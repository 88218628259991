const general = [
    {
        title: "Where are we based?",
        description: ["Multiverse Funding is trading name of MULTIVERSE FD LTD, a United Kingdom registed Company"],
        list: []
    },
    {
        title: "How Do I Get Started With Multiverse Funding?",
        description: [`You can begin your journey as a Master Trader by purchasing any of the Paper-Trading Evaluations`, ` Unlock up to $600,000 Challenge size.
     Once the account is purchased, an account is automatically created for you and you will be emailed your MultiTrader credentials and ready to start trading
    `],
        list: []
    },
    {
        title: "I Passed The Challenge, What's Next?",
        description: [`Congratulations! Ensure that all positions are closed and verify that you have achieved a minimum percentage gain on the challenge account while adhering to all challenge parameters.`],
        list: ["Click update on your dashboard after passing phase 1, our system will generate a new account for next phase and send it to you, once all the phases are passed, click upgrade to get  your  Master trader Account. And the account will be ready for you to trade after you complete your KYC verification"]
    }, {
        title: "is Weekend holding allowed?",
        description: [`weekend holding is allowed on 2 step evaluation but it  its not allowed on 1 step evaluation, to hold your trade over the weekend on a 1 Step  HFT series, you have to send an email to support@multiverse-funding.com explaining you would like to hold your trade over the weekend in advance, after approval, you can hold your trade.`],
        list: []
    },
    , {
        title: "Lot size allowed on our evalution & master account",
        description: [],
        list: ['25k: 10 lot size', '50k: 15 lot size', '100k 25 lot size', '200k 30 lot size']
    },
    {
        title: "How Are Payouts Processed?",
        description: [`All commission payouts are facilitated using Cryptocurrency & Bank Transfer through Rise.`],
        list: [`All traders who have will receive  over 500usd for payout will be able to get it through Rise (Bank Transfer&Crypto)`,
            `all traders who have less than 500usd will be paid directly to their crypto wallet(BTC Network & USDT ERC20 & USDT TRC20)`,
            `All transaction fee will be included in the payout sent to traders`,
            `All payout are processed within 12 working hours`,
        ]
    },
    {
        title: "What Trading Styles Are Accepted in this Program?",
        description: [`Whether you prefer Support/Resistance, Candlestick Patterns, EA’s, Supply/Demand, or Smart Money Concepts, feel free to apply them on our simulated accounts.

    Recognizing the diversity of successful traders worldwide, we appreciate varied strategies and backgrounds.
    We aim for openness in the choice of trading styles during the program. The mentioned strategies are restricted during the experienced trader stage due to reasons such as inability to replicate in the live market, reliance on demo conditions only, or their potential to manipulate the system. While there are no restrictions during the evaluation stage, the following strategies/approaches are prohibited during the Master trader stage and will undergo review:
    `],
        list: [
            "Grid Trading or Grid Trading Softwares",
            "Martingale Trading or Martingale EA’s",
            "Latency Arbitrage",
            "Hedging Orders Across Multiple Accounts",
            "Exploiting News Volatility Through Guaranteed Limit Order Fills",
            "Using Delayed Data Feeds for Risk-Free Profit",
            "Copy Trading of Another Person's Signals/System or Copy Trading Among Multiple Users on Our Platform",
            "Account Management by a 3rd Party",
            "tick scalping(less than 1 minute)"
        ]
    },
    {
        title: "When will I get my account credentials after payment?",
        description: ["You can anticipate receiving your account credentials within 5 minutes of completing your payment. Once your account is established, you will be notified via email. In case our email is not found in your inbox after the payment, kindly check your spam/junk folder. (in case you do not get your credentials after purchase, kindly chat support@multiverse-funding.com team providing your email/payment reference)"],
        list: []
    }
    , {
        title: "what is the max allocation i can be funded?",
        description: ["you can be funded up to $600,000 the max allocation for 1 Step (HFT series) is $200,000 and for 2 step evaluation is $400,000"],
        list: []
    }, {
        title: "Do you allow account merging?",
        description: ["Account merging is currently not permitted at this time."],
        list: []
    }, {
        title: "Is there a minimum withdrawal amount?",
        description: ["the minimum amount to withdraw is $100 for 1 Step (HFT series) and there is not minimum amount for 2 step master account withdrawal"],
        list: []
    }, {
        title: "Will I get a certificate once I pass to a funded account?",
        description: ["Your skills and knowledge are highly valued, and in recognition of your achievements, you will be awarded a certificate from Multiverse Funding."],
        list: []
    }, {
        title: "Is hedging allowed?",
        description: ["While hedging is permitted, engaging in the practice of using multiple accounts to hedge entries between them will be regarded as a violation of the rules."],
        list: []
    }, {
        title: "Can We Trade High Impact News?",
        description: ["Yes, news trading is allowed on both 1 Step and 2-step evaluation accounts, but with restrictions. You are not permitted to open or close positions within 5 minutes before or after major news events. Executing trades within this restricted timeframe will result in the termination of your trading account."],
        list: []
    }, {
        title: "Is there an inactivity rule?",
        description: ["Yes, inactivity begins immediately after account purchase. If there is no activity within 30 days, the account will be terminated. To avoid this, kindly ensure regular activity or request assistance at support@multiverse-funding.com."],
        list: []
    }, {
        title: "which broker do we use?",
        description: ["We have our own trading platform called MultiTrader which is similar to mt4, and was build to give me the best of trading experience"],
        list: []
    }
, {
        title: "Gambling is Not a Viable Trading Strategy",
        description: ["At Multiverse Funding, we emphasize disciplined and responsible trading. Gambling is not the right way to make money, and traders are strictly prohibited from engaging in high-risk, speculative behaviors. To ensure proper risk management, the maximum allowable risk per trade is capped at 0.25% to 1% of your total account balance. Risking more than 1% per trade violates our policy and may result in the reduction of your leverage or termination of your account. Maintaining consistency in risk management is key to long-term success and account sustainability."],
        list: []
    }
]


const OneStep = [
    {
        title: "What is the 1 Step HFT series",
        description: ["Easily pass your 1-step evaluation challenge with an HFT bot and quickly gain access to your master account."],
        list: []
    },
    {
        title: "Consistency rules",
        description: [`The consistency rule applies to all 1 Step HFT series. Traders must actively participate for a minimum of 5 trading days before each withdrawal. Upon initiating a withdrawal request, we conduct a thorough review of your trading history and make adjustments to your balance for any trades that deviate from your designated trading range.
    To determine your trading range, start by calculating your average trade size. Divide the total lot volume you've traded by the number of executed trades to find your average lot size.For example:`],
        list: [
            `Total lots traded: 120`,
            `Total trades executed: 60`,
            `Average lot size= Total trades executed/Total lots traded= 120/60=2`,
            `Now, establish your average lot size range:`,
            `Bottom of the range=Average lot size×0.3`,
            `Top of the range=Average lot size×1.8`,
            `Bottom of the range=2×0.3=0.6Lots`,
            `Top of the range=2×1.8=3.6Lots`,
            `In this revised example, your trades must fall within the range of 0.6 and 3.6 lots. Any trades outside of this specified range will be excluded at the time of withdrawal.`
        ]
    }, {
        title: "Profit Share & Payouts",
        description: [`
    Traders who utilize High-Frequency Trading to successfully navigate the 1-step challenges will be governed by the following payout structure:`,
            `1st Withdrawal: `,
            `-----30 days of the initial trade`,
            `-----10 minimum trading days`,
            '-----5% profit cap',
            '-----50% profit split', "",
            `2nd Withdrawal: `,
            `-----21 days of the initial trade`,
            `-----10 minimum trading days`,
            '-----5% profit cap',
            '-----70% profit split',
            `3rd Withdrawal: `,
            `-----21 days of the initial trade`,
            `-----10 minimum trading days`,
            '-----5% profit cap',
            '-----80% profit split',
            `4th Withdrawal: `,
            `-----14 days of the initial trade`,
            `-----10 minimum trading days`,
            '-----5% profit cap',
            '-----90% profit split',
            `5th Withdrawal: `,
            `-----14 days of the initial trade`,
            `-----5 minimum trading days`,
            '-----6% profit cap',
            '-----100% profit split'
        ],
        list: []
    },
    {
        title: "Is there a Trailing Daily Loss on the 1 Step  HFT series ?",
        description: ["No our daily drawdown currently is static on all our trading account, its 4% from your daily starting balance"],
        list: []
    },
    {
        title: "Whats is the Max Loss on the 1 Step  HFT series ?",
        description: ["For our 1 Step  HFT series /Master Trader Stage we have a trailing Max Loss rule. This means you can't lose more then 8% from your highest equity point. To determine the lowest equity you can have before violating this rule you can use this formular. 'Lowest Equity = Highest Equity x 0.92' for example: Lets say you just purchased a $100,000 evaluation"],
        list: []
    }, {
        title: "Do we allow copy trade on 1 Step  HFT series",
        description: [`
    Copying trades between 1 Step ( HFT series) is prohibited. Each account must operate independently. For instance, if you buy EURUSD in one 1 Step  HFT series  account, you cannot execute transactions involving the same asset (EURUSD) in another 1 Step  HFT series  account.`],
        list: []
    }, {
        title: "Stacking Rule?",
        description: ["On 1 Step ( HFT series), stacking is not permitted, meaning you cannot have more than two positions open simultaneously for the same currency pair in same direction. Violating this rule will be considered as hard breached and will lead to the termination of your trading account during review upon payout."],
        list: []
    }, {
        title: "What are trades considered valid for payout",
        description: ["all trades that follow the rules below are eligible for payout, if total deduction is more than profit made, you will be given a new acount for a retry"],
        list: [
            `trades should follow stacking rules`,
            `should fall within the consistency range`,
            `each position should not be more than 35% of total profit eligible by the consistency range(example if you make 3000usd from a 100k account and 2500USD are valid after the consistency range review, each position close can not be more than 875USD, position exceeding 35% will be deducted at payout stage),`,
            `each trading day can not be more than 35% of total profit(example if you make 3000usd from a 100k account, each trading day PNL can not be more than 1050USD, position exceeding 35% will be deducted at payout stage)`,
            `Notice that opening 2 positions within 15 minutes period on an asset will be considered as 1 position during the review for the 35% rule, as well as closing a running position and reopening within 15min will still be considered as 1 position`
        ]
    }, {
        title: "are partials considered positions(trade)",
        description: ["Yes, each partial is  considered as a stand alone position for consistency rule but, they are not considered as positions for 35% rule"],
        list: []
    },
    {
        title: "Real trading days on 1 Step",
        description: ["Adjusting your position size and actively opening and closing positions to meet the trading day requirements will not be considered as trading days. For instance, if your typical trade duration ranges from 30 minutes to 1 hour, but suddenly you close a trade within 3 minutes, or if you initially trade with positions of 5 to 10 lots and later reduce it to 0.1 to 0.01 lots, those instances will not count as trading days"],
        list: []
    }


]
const TwoStep = [
    {
        title: "Profit Share & Payouts",
        description: [`
        Traders who uses their skill to trade and successfully navigate the 2-step challenges will be governed by the following payout structure:`,
            `1st Withdrawal: `,
            `-----21 days of the initial trade`,
            `-----3 minimum trading days`,
            '-----80% profit split', "",
            `2nd Withdrawal: `,
            `-----14 days of the initial trade`,
            `-----3 minimum trading days`,
            '-----90% profit split',
            `3rd Withdrawal: `,
            `-----7 days of the initial trade`,
            `-----No Minimum trading days`,
            '-----100% profit split'],
        list: []
    },
    {
        title: "Is There any Consistency rules",
        description: ["No, there's no consistency rule on the 2 step evaluation, your consistency is proven after passing phase 1 and phase 2 of the challenge, you can trade any lot size that is permitted"],
        list: []
    }, {
        title: "How to get phase 2 login",
        description: ["Click upgrade on your dashboard and you will get your phase 2 within 24h"],
        list: []
    },
    {
        title: "What are the Profit Target for a 2-step Evaluation?",
        description: ["In order to become a Master Trader, we expect you to reach certain profit targets in the evaluations stage. The profit target for the 2-step challenge is 8% in Phase 1 and 5% in Phase 2. This means if you purchased a $100,000 evaluation, in order to advance to the Master Trader stage you must earn $10,000 in closed profit in Phase 1 and $5,000 closed profit in Phase 2. After reaching the profit target you will receive your Master Trader account."],
        list: []
    },
    {
        title: "Is there a Trailing Daily Loss on the 2-step Evaluation?",
        description: ["For our 2-step evaluation and in the Master Trader Stage we don't apply daily trailing loss rules. For the 2-step evaluation we use a fixed daily loss. At any given moment of the day your max daily loss will be 5% + any profit made that day. For example you have a starting balance of $100,000 you can lose $5,000. Now lets say you made $2,000 profit today, your max loss will be $5,000 + $2,000 = $7,000 for the day. Please be aware that the day resets at midnight. Go to your dashboard to see the daily reset timer."],
        list: []
    },
    {
        title: "What is the Max Loss on the 2-step Evaluation?",
        description: ["For our 2-step Evaluation/Master Trader Stage we use a fixed max loss of 10%. This means that your Lowest Equity Point will always be fixed to 10% from the starting balance.", " For example: you have a $100,000 Evaluation, you can lose $10,000. The more profit you make the more you will be able to lose before violating. For axample: you made $15,000 profit, now your Max Loss will be $10,000 + $15,000."],
        list: []
    }, {
        title: "What happens if i lose my phase 2 challenge account?",
        description: [`If you fail your Phase 2 challenge, please send an email to support@multiverse-funding.com if you wish to request a retry for your Phase 2. Upon payment of the retrial fee and approval by the support team, you will be granted a second chance (last chance) to attempt Phase 2 with new account credentials that will be sent to your email.
         Retrial Fee: 2x price of the challenge fee without any discount.     
         For example, if you purchased a 100k 2-step evaluation and failed Phase 2, after contacting support and paying the retrial fee of a 100k 2-step account (2x $495 USD), you will receive a new 100k evaluation. This new evaluation will require you to achieve a 5% on the account size to obtain the master account.`],
        list: []
    }
]
export { general, OneStep, TwoStep };
