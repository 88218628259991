import * as React from 'react';
import AffliateIcon from "../img/affiliate.png";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerIcon from '@mui/icons-material/Timer';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
function WhyUs() {
    return (
        <section class="section-home-service">
            <div class="padding-global">
                <div class="container-medium">
                    <div class="padding-section-large">
                        <div class="home-service-component">
                            <div id="w-node-c7f78e1e-5368-c43b-7ae8-19d6e8769c42-438edebb" class="home-service-top-wrapper">
                                <div class="spacer-small"></div>
                                <h2 id="w-node-c7f78e1e-5368-c43b-7ae8-19d6e8769c43-438edebb">Why Multiverse Funding</h2>
                            </div>
                            <div class="spacer-xlarge"></div>
                            <div id="w-node-b42eee1b-1cf7-a3f7-b8c8-321bd06fd71c-438edebb" class="home-service-list">
                                <div id="w-node-c7f78e1e-5368-c43b-7ae8-19d6e8769c47-438edebb" class="home-service-item">
                                    <AccessTimeIcon sx={{ fontSize: 50, color: "#0c664f" }} />
                                    <div class="spacer-small"></div>
                                    <div class="home-service-divider-row">
                                        <div class="home-service-divider-circle"></div>
                                        <div class="home-service-divider-line"></div>
                                    </div>
                                    <div class="spacer-small"></div>
                                    <div class="home-service-content-wrapper">
                                        <div class="text-weight-bold">Fast Payout</div>
                                        <div class="spacer-xsmall"></div>
                                        <div>Enjoy industry-leading payout speeds with our efficient system. All payouts are processed within 12 hours, ensuring you get your earnings faster than ever</div>
                                    </div>
                                </div>
                                <div class="home-service-item">
                                    <SmartToyIcon sx={{ fontSize: 50, color: "#0c664f" }} />
                                    <div class="spacer-small"></div>
                                    <div class="home-service-divider-row">
                                        <div class="home-service-divider-circle"></div>
                                        <div class="home-service-divider-line"></div>
                                    </div>
                                    <div class="spacer-small"></div>
                                    <div class="home-service-content-wrapper">
                                        <div class="text-weight-bold">HFT allowed</div>
                                        <div class="spacer-xsmall"></div>
                                        <div>Pass your 1-Step Evaluation effortlessly using an HFT bot and get funded faster than ever. High-Frequency Trading (HFT) is fully allowed</div>
                                    </div>
                                </div>
                                <div class="home-service-item">
                                    <AttachMoneyIcon sx={{ fontSize: 50, color: "#0c664f" }} />
                                    <div class="spacer-small"></div>
                                    <div class="home-service-divider-row">
                                        <div class="home-service-divider-circle"></div>
                                        <div class="home-service-divider-line"></div>
                                    </div>
                                    <div class="spacer-small"></div>
                                    <div class="home-service-content-wrapper">
                                        <div class="text-weight-bold">100% Profit Split</div>
                                        <div class="spacer-xsmall"></div>
                                        <div>Everything you earn is yours to keep, with no hidden fees or deductions. Your success, your rewards!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WhyUs;
