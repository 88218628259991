import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, Typography, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Input } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import io from "socket.io-client";
import { Snackbar } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
const HFTPage = () => {

  const URL = process.env.NODE_ENV === 'production' ? "https://www.multiverse-funding.com" : "http://localhost:8080";
  let SOCKET_URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";

  const [partners, setPartners] = useState([]);
  const [alert, setAlert] = useState({ open: false, message: "", severity: "" });


  useEffect(() => {
      fetchPartners();
  }, []);

  const fetchPartners = async () => {
      try {
          const response = await axios.get(`${URL}/api/partners`);
          console.log(response.data)
          setPartners(response.data);
      } catch (error) {
          setAlert({ open: true, message: "Error fetching partners", severity: "error" });
      }
  };

  const [accountNumber, setAccountNumber] = useState('');
  const [indice, setIndice] = useState('');
  const [lotSize, setLotSize] = useState(0);
  const [positions, setPositions] = useState([]);
  const [Details, setDetails] = useState(null)
  const [US30, setUS30] = useState(null)

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [isRunning, setIsRunning] = useState(false); // State to track if the process is running
  const [intervalId, setIntervalId] = useState(null); // Store interval ID to stop it later

  const handleLoadAccount = () => {
    if (accountNumber !== "") {
      axios.post(`${URL}/api/find-trading-acccount`, {
        accountId: accountNumber
      })
        .then(res => {
          setDetails(res.data)
          fetchDataAndSetupSocket(res.data.accountId);
        })
        .catch(err => {
          console.log(err)
        })
    }
  };

  const closeOrder = async () => {
    try {
      const close_all_position = `https://app.multiverse-funding.com/api/CloseAll`;
      const payload = {
        accountId: Number(accountNumber), // Ensure accountId is a number
      };

      const { data } = await axios.post(close_all_position, payload);
      // console.log('Close All Response:', data); // Log only relevant data
      return data; // Return response if needed
    } catch (error) {
      console.error('Failed to close order:', error.response?.data || error.message);
    }
  };



  // Function to stop the process
  const handleStop = () => {
    setIsRunning(false);
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval
      setIntervalId(null); // Reset the interval ID
    }
  };

  // Function to start the process (runs every 5 seconds)
  const handleStart = () => {
    if (!isRunning) {
      setIsRunning(true);
      const id = setInterval(() => {
        console.log('running')
        if (US30) {
          handleSubmit();
        }
      }, 2500); // Repeat every 5 seconds
      setIntervalId(id); // Save interval ID so we can stop it later
    }
  };

  // Submit the order using an API call
  const handleSubmit = async () => {
    // Define the parameters
    const Buy_param = {
      symbol: indice,
      action: 'Buy',
      lot: lotSize,
      accountId: accountNumber,
      entryPrice: US30.bid - 10,
    };

    const Sell_param = {
      symbol: indice,
      action: 'Sell',
      lot: lotSize,
      accountId: accountNumber,
      entryPrice: US30.bid + 10,
    };

    // Randomly select a parameter object
    const params = Math.random() < 0.5 ? Buy_param : Sell_param;

    try {
      // Submit the selected parameter
      const response = await axios.post(`${URL}/api/add-position`, params);
      // console.log('Response:', response);
      setTimeout(() => { closeOrder() }, 1500)
      // Show success message
      setSnackbarMessage('Position submitted successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error submitting position:', error);
      // Show error message
      setSnackbarMessage('Failed to submit position.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };




  // START THE WEBSOCKET TO GET LIVE DATA
  const fetchDataAndSetupSocket = async (id) => {
    const socket = io(`${SOCKET_URL}/${id}`, {
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });
    socket.on('dataFeed', (data) => {
      setUS30(data.US30)
      // console.log(data.US30)
    });
    socket.on('userLivedata', (data) => {
      if (data !== null) {
        setPositions(data.openPositions);
        setDetails((prevDetails) => ({
          ...prevDetails,
          CurrentBalance: data.CurrentBalance, 
          equity:data.equity
        }));
      }
    });
    return () => socket.disconnect();
  };

  //text style
  const textStyle = {
    fontFamily: 'Cabin, sans-serif',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    textAlign: 'center',
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>HFT Passing</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <TextField
            label="Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            fullWidth
          />
          <Button variant="contained" sx={{ mt: 1 }} color="primary" onClick={handleLoadAccount}>
            Load Account
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 350, height: 120, margin: 2 }}>
            <CardContent>
              <Typography color="text.secondary">
                <div style={textStyle} className='first_Container'>
                  <ul>
                    <li>Balance</li>
                    <li>Equity</li>
                  </ul>
                  <ul>
                    <li>{Details !== null ? formatCurrency(Details.CurrentBalance) : "0.00"}</li>
                    <li>{Details !== null ? formatCurrency(Details.equity) : "0.00"}</li>

                  </ul>
                </div>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Indice</InputLabel>
            <Select
              value={indice}
              onChange={(e) => setIndice(e.target.value)}
            >
              <MenuItem value="US30">US30</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Lot Size"
            value={lotSize}
            onChange={(e) => setLotSize(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            disabled={isRunning}
          >
            Run HFT
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleStop}
            disabled={!isRunning}
            style={{ marginLeft: 10 }}
          >
            Stop HFT
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Opened Positions</Typography>
          {positions.map((position, index) => (
            <Table sx={{ minWidth: 300, maxWidth: 500 }} aria-label="simple table">
              <TableBody>
                <TableRow
                  className='positions-opened'
                >
                  <TableCell className='positions-opened-symbol'>
                    {position.symbol} <span style={{color:position.action=='Buy'?"blue":"red"}}>{position.action} {position.lot}</span> at {position.entryPrice}
                  </TableCell>
                  <TableCell align="right" style={{ color: position.profit < 0 ? "red" : "blue", fontSize: "20px", fontWeight: "bold" }}>
                    {position.profit !== null ? position.profit.toFixed(2) : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default HFTPage;

function formatCurrency(price, currency = 'USD', locale = 'en-US') {
  return price.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2, // Ensures the decimal part is always shown
  });
}

const filledColor = 'blue'; // Green for filled part
const trackColor = 'rgba(217, 217, 217, 1)'; // White for the track (unfilled part)
function CircularProgressWithLabel({ value, filledColor, trackColor }) {
  return (
    <Box style={{ marginTop: "20px" }} position="relative" display="inline-flex" alignItems="center" justifyContent="center">
      {/* Track as background with 100% progress */}
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: trackColor, // Track color (unfilled part)
          position: 'absolute',
          zIndex: 1,
        }}
        size={80}
        style={{ marginTop: "20px" }}
      />
      {/* Actual progress */}
      <CircularProgress
        variant="determinate"
        value={value}
        size={80}
        sx={{
          color: filledColor, // Filled part color
          position: 'absolute',
          zIndex: 2,
        }}
        style={{ marginTop: "20px" }}
      />
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={3} // Ensure the text is above both CircularProgress components
      >
        <Typography style={{ fontSize: "20px", color: "rgba(12, 11, 11, 1)", marginTop: "20px" }} variant="caption" component="div" color="text.secondary">
          {`${value.toFixed(1)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function convertToHumanReadableTime(isoString) {
  const date = new Date(isoString);
  const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
  const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
  const humanReadableCustom = `${date.toLocaleDateString('en-US', optionsDate)} at ${date.toLocaleTimeString('en-US', optionsTime)}`;
  return humanReadableCustom;
}