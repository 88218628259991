import line from "../img/line.png";
import logo from "../img/logo.png";
import pilot from "../img/trustpilot.png";
import popup from "../img/discount.png";
import React from "react";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import { SocialIcon } from 'react-social-icons'
import axios from 'axios'
import 'react-social-icons/discord'
import 'pure-react-carousel/dist/react-carousel.es.css';
import { TextField } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Localbase from 'localbase'
import Nav from "../navbar/Nav";
import Footer from "../footer/Footer";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'
import PayoutSchedules from './PayoutSchedules'
import Community from './Community'
import WhyUs from './WhyUs'
import Affiliate from './Affiliate'
import Platform from './Platform'
import Partner from './Partner'
import CircularProgress from '@mui/material/CircularProgress';
import { addsize, addprice, addstep, addPlatform } from '../redux/counterSlice';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  TableContainer,
  Paper,
  Collapse,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

AOS.init();

function Home(props) {

  //affliate link
  const affliateUrl = "https://www.multiverse-funding.com/affiliate"
  //redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //alert for successfull message sent to support
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  //url for mailchimp email
  const url = "https://multiverse-funding.us9.list-manage.com/subscribe/post?u=87633bc5be15cc03d0aa0bb19&amp;id=0449c4f8be&amp;f_id=001e1be1f0"
  const SimpleForm = () => <MailchimpSubscribe url={url} />
  //local storage
  let db = new Localbase('multiversefundingdb')
  //handlers
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(false)
  const [openerror, setOpenerror] = React.useState(false);
  const [checker, setchecker] = useState(false)
  const handleClick = () => {
    setOpen(true);
  };
  const handleClickerror = () => {
    setOpenerror(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCloseerror = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenerror(false);
  };
  const [openemail, setOpenemail] = React.useState(false);

  const handleClickOpenemail = () => {
    setOpenemail(true);
  };

  const handleCloseemail = () => {
    setOpenemail(false);
  };

  const [promos, setPromos] = useState([]);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  let URL;
  if (process.env.NODE_ENV === 'production') {
    URL = "https://www.multiverse-funding.com";
  } else {
    URL = "http://localhost:8080";
  }

  useEffect(() => {
    fetchPromos();
  }, []);

  const fetchPromos = async () => {
    try {
      const response = await axios.get(`${URL}/api/promos`);
      setPromos(response.data[response.data.length - 1]);
    } catch (error) {
      setAlert({ open: true, message: 'Error fetching promos', severity: 'error' });
    }
  };

  const [selectedBalance, setSelectedBalance] = useState(10000);
  const [challengeType, setChallengeType] = useState("1 Step"); // Default to 1 Step
  const [openDescriptions, setOpenDescriptions] = useState({}); // Track which rows are expanded
  const [dynamicSteps, setDynamicSteps] = useState({
    "1 Step": {},
    "2 Step": {},
  });

  const handleBalanceChange = (event, newValue) => {
    setSelectedBalance(newValue);
  };

  const handleChallengeTypeChange = (event, newType) => {
    if (newType) {
      setChallengeType(newType);
    }
  };

  const toggleDescription = (parameter) => {
    setOpenDescriptions((prevState) => ({
      ...prevState,
      [parameter]: !prevState[parameter],
    }));
  };

  const calculateDynamicValues = () => {
    const dailyLoss = challengeType === "1 Step" ? 0.04 : 0.05;
    const maxLoss = challengeType === "1 Step" ? 0.08 : 0.1;
    const profitTargetPhase1 = challengeType === "1 Step" ? 0.08 : 0.07;
    const profitTargetPhase2 = challengeType === "2 Step" ? 0.05 : null;

    const steps = {
      "1 Step": {
        "Phase 1": {
          tradingPeriod: "Unlimited",
          minDays: "X",
          maxDailyLoss: `$${(selectedBalance * dailyLoss).toLocaleString()}`,
          maxLoss: `$${(selectedBalance * maxLoss).toLocaleString()}`,
          profitTarget: `$${(selectedBalance * profitTargetPhase1).toLocaleString()}`,
          HFT: "Allowed",
        },
        "Master Trader": {
          tradingPeriod: "Unlimited",
          minDays: "5 Days",
          maxDailyLoss: `$${(selectedBalance * dailyLoss).toLocaleString()}`,
          maxLoss: `$${(selectedBalance * maxLoss).toLocaleString()}`,
          profitTarget: `X`,
          HFT: "Not allowed",
        },
      },
      "2 Step": {
        "Phase 1": {
          tradingPeriod: "Unlimited",
          minDays: "3 Days",
          maxDailyLoss: `$${(selectedBalance * dailyLoss).toLocaleString()}`,
          maxLoss: `$${(selectedBalance * maxLoss).toLocaleString()}`,
          profitTarget: `$${(selectedBalance * profitTargetPhase1).toLocaleString()}`,
          HFT: "Not allowed",
        },
        "Phase 2": {
          tradingPeriod: "Unlimited",
          minDays: "3 Days",
          maxDailyLoss: `$${(selectedBalance * dailyLoss).toLocaleString()}`,
          maxLoss: `$${(selectedBalance * maxLoss).toLocaleString()}`,
          profitTarget: `$${(selectedBalance * profitTargetPhase2).toLocaleString()}`,
          HFT: "Not allowed",
        },
        "Master Trader": {
          tradingPeriod: "Unlimited",
          minDays: "X",
          maxDailyLoss: `$${(selectedBalance * dailyLoss).toLocaleString()}`,
          maxLoss: `$${(selectedBalance * maxLoss).toLocaleString()}`,
          profitTarget: "X",
          HFT: "Not allowed",
        },
      },
    };

    setDynamicSteps(steps);
  };

  useEffect(() => {
    calculateDynamicValues();
  }, [selectedBalance, challengeType]);

  const data = {
    balanceOptions: [10000, 25000, 50000, 100000, 200000],
    descriptions: {
      tradingPeriod:
        "Peace of mind is crucial to focus on your trading performance. The Trading Period is completely unlimited, allowing you to take as much time as needed to reach your Profit Target.",
      minDays: "This is the minimum number of days required to complete your challenge.",
      maxDailyLoss:
        "The Maximum Daily Loss sets a limit on your losses within a single day to promote responsible trading.",
      maxLoss: "This is the maximum amount of loss allowed throughout the challenge.",
      profitTarget: "The Profit Target is the amount of profit you need to reach to pass the challenge.",
      HFT: "Usage of high-frequency trading bot to pass challenge.",
    },
    pricing: {
      "1 Step": {
        10000: 179,
        25000: 279,
        50000: 379,
        100000: 579,
        200000: 979,
      },
      "2 Step": {
        5000: 39,
        10000: 69,
        25000: 149,
        50000: 249,
        100000: 449,
        200000: 999,
      },
    },
  };

  const getPrice = () => {
    return data.pricing[challengeType][selectedBalance];
  };

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [comment, setComment] = useState("")
  const [realcomment, setReaComment] = useState("")
  const section1Ref = React.useRef(null);
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-component w-nav">
          <Nav />
          {/* <div className="padding-global is-nav"> */}
          {/* <div className="nav-container">
            <a href="index.html" by-text="inherit" aria-current="page" className="nav-logo-link w-nav-brand w--current"><img src={logo} loading="lazy" alt="Soios logo in white" width='50' /></a>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div className="nav-menu-layout">
                <div className="nav-menu-left-wrapper">
                  <a href="https://www.multiverse-funding.com/" aria-current="page" className="nav-link w-inline-block w--current">
                    <div>Home</div>
                  </a>
                  <div data-hover="true" data-delay="0" className="nav-dropdown w-dropdown">
                  </div>
                  <div data-hover="true" data-delay="0" className="nav-dropdown w-dropdown">
                    <div className="nav-dropdown-toggle w-dropdown-toggle">
                      <div className="nav-link">
                        <div onClick={() => scrollToSection(section1Ref)}>Challenge</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nav-menu-right-wrapper">
                  <a href="https://www.multiverse-funding.com/support" className="nav-link w-inline-block">
                    <div>Support</div>
                  </a>
                  <a href="https://www.multiverse-funding.com/faq" className="nav-link w-inline-block">
                    <div>FAQ</div>
                  </a>
                  <div className="nav-search-icon-wrapper"><img src="/images/Vectors-Wrapper_8.svg" loading="lazy" width="18" height="18" alt="" className="nav-search-icon-image" /></div>
                  <div className="nav-button-wrapper">
                    <a href="https://www.multiverse-funding.com/Dashboard" target="_blank" className="button is-nav w-button">Login</a>
                  </div>
                </div>
              </div>
            </nav>
            <div
              className="menu-button w-nav-button"
            >
              <img
                src="/images/Nav-Menu-Icon.svg"
                loading="lazy"
                alt="Menu icon"
                className="menu-icon"
              />
            </div>

          </div> */}
          {/* </div> */}
          <div className="nav-background"></div>
        </div>
        <main className="main-wrapper">
          <header className="section-home-header">
            <div className="nav-spacer"></div>
            <div className="padding-global">
              <div className="spacer-xlarge"></div>
              <div className="container-large">
                <div className="home-header-component">
                  <div data-w-id="ec134eb5-1884-3910-8720-9b0539343dd8" style={{ opacity: 1 }} className="home-header-content-wrapper">
                    <div className="subheading">Multiverse Funding</div>
                    <div className="spacer-small"></div>
                    <div id="w-node-_56ba5084-4abf-b2f8-97ed-9392b7b31000-438edebb" className="home-header-heading-wrapper">
                      <h1 id="w-node-_5af9a5a3-a5de-b6f2-d7fe-c1fb2add7e59-438edebb">Practice Trading, Earn Rewards!</h1>
                    </div>
                    <div className="spacer-small"></div>
                    <div className="home-header-description">Complete the evaluation with up to $600,000 in funding and keep 100% of the profits you make on a live simulated trading account!</div>
                    <div className="spacer-small"></div>
                    <div className="button-group">
                      <div onClick={() => scrollToSection(section1Ref)} className="button header-button w-button">Evaluation Plans</div>
                    </div>
                  </div>
                  <div className="spacer-large"></div>
                  <div data-w-id="b720a564-1cdb-8e40-af06-960777d16ee9" style={{ opacity: 1 }} className="home-header-image-wrapper"><img src="/images/DASH.png" loading="lazy" width="956" id="w-node-e74ddb87-d8bc-15dc-4818-6e48b090ee1d-438edebb" alt="Dashboard screenshot" srcset="/images/DASH.png 500w, /images/DASH.png 800w, /images/DASH.png 1080w, /images/DASH.png 1912w" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 92vw" className="image-autofill header-image" />
                    <div data-w-id="9fceee58-8884-7999-afbc-f43b0f3dd420" style={{ opacity: 1 }} className="home-header-glow"></div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="section-home-service">
            <div className="stages" data-aos="fade-up">
              <br /><br />
              <h3 style={{ display: "flex", justifyContent: "center" }}>Stage Process to become a Master Trader</h3>
              <div className="roadmap">
                <div className="roadmap-left"><br />
                  <h4>Evaluation Stage</h4>
                  <img src={line} alt="#" />
                  <nav>
                    <div>
                      <h3>01.</h3>
                      <h5>Multiverse Funding Challenge</h5>
                      <p>Reaching the profit target on our challenge account is your key to proving your skills and becoming a Master Trader.</p>
                    </div>
                    <div>
                      <h3>02.</h3>
                      <h5>KYC</h5>
                      <p> After hitting the profit target and adhering to all rules, you'll proceed to the KYC process for identity verification. </p>
                    </div>
                  </nav>
                  <br />
                  <br />
                </div>

                <div className="roadmap-right">
                  <h4>Earn Real Money</h4>
                  <div>
                    <h3>03.</h3>
                    <h5>Master Trader</h5>
                    <p>The final stage is becoming a Master Trader, where you start earning real money. Virtual profits will be paid out as actual cash.</p>
                  </div>
                </div>
              </div>

            </div>
            <br />
            <br />

          </div>



          <section className="section-home-pricing" ref={section1Ref}>

            <Box
              p={3}
              sx={{
                maxWidth: "800px", // Set the maximum width
                width: "100%", // Ensure it adapts to smaller screens
                margin: "0 auto", // Center horizontally
              }}
            >
              <Tabs
                value={selectedBalance}
                onChange={handleBalanceChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: { display: "none" }, // Hide the default indicator
                }}
                sx={{
                  "& .MuiTab-root": {
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "4px",
                    margin: "0 4px",
                    textTransform: "none", // Keep the text case as provided
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#0c664f", // Selected button color
                    color: "white",
                  },
                }}
              >
                {data.balanceOptions.map((balance) => (
                  <Tab
                    key={balance}
                    label={`$${balance.toLocaleString()}`}
                    value={balance}
                  />
                ))}
              </Tabs>

              <Box mt={3} display="flex" justifyContent="center">
                <ToggleButtonGroup
                  value={challengeType}
                  exclusive
                  onChange={handleChallengeTypeChange}
                  aria-label="challenge type"
                  sx={{
                    "& .MuiToggleButton-root": {
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid #ddd",
                      textTransform: "none",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#0c664f",
                      color: "white",
                      borderColor: "#0c664f",
                    },
                  }}
                >
                  <ToggleButton value="1 Step" aria-label="1 Step">
                    1 Step
                  </ToggleButton>
                  <ToggleButton value="2 Step" aria-label="2 Step">
                    2 Step
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <br />
              <TableContainer component={Paper} mt={3}>
                <Table
                  sx={{
                    fontSize: "0.8rem", // Smaller text for the entire table
                    "& th, & td": {
                      padding: "4px", // Reduce padding for compactness
                    },
                    "& th": {
                      fontWeight: "bold",
                      textAlign: "center", // Center align column headers
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell><b></b></TableCell>
                      {Object.keys(dynamicSteps[challengeType]).map((step, index) => (
                        <TableCell key={index} align="center">
                          <b>{step}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {["tradingPeriod", "minDays", "maxDailyLoss", "maxLoss", "profitTarget", "HFT"].map(
                      (parameter, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                <IconButton
                                  size="small"
                                  onClick={() => toggleDescription(parameter)}
                                >
                                  {openDescriptions[parameter] ? (
                                    <KeyboardArrowUp />
                                  ) : (
                                    <KeyboardArrowDown />
                                  )}
                                </IconButton>
                                <Typography sx={{ fontSize: "0.75rem" }}>
                                  {parameter
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, (str) => str.toUpperCase())}
                                </Typography>
                              </Box>
                            </TableCell>
                            {Object.keys(dynamicSteps[challengeType]).map((step, stepIndex) => (
                              <TableCell key={stepIndex} align="center">
                                {dynamicSteps[challengeType][step][parameter]}
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={Object.keys(dynamicSteps[challengeType]).length + 1}
                            >
                              <Collapse
                                in={openDescriptions[parameter]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{ fontSize: "0.65rem" }} // Smaller font for descriptions
                                >
                                  {data.descriptions[parameter]}
                                </Typography>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={3} textAlign="center">
                <Typography variant="body1" sx={{ color: "white", fontSize: 20 }}>
                  <b>$ {selectedBalance.toLocaleString()} {challengeType} For</b> ${getPrice()}
                </Typography>

                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    mt: 2,
                    backgroundColor: "#0c664f",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#08573e", // Darker shade for hover effect
                    },
                    "&:active": {
                      backgroundColor: "#0c664f",
                      color: "white",
                    },
                    "&:focus": {
                      outline: "none", // Optional: Remove focus outline
                    },
                  }}
                  onClick={() => {
                    dispatch(addsize(Number(selectedBalance) || 0))
                    dispatch(addprice(Number(data.pricing[challengeType][selectedBalance]) || 0))
                    dispatch(addPlatform('MultiTrader' || 0))
                    dispatch(addstep(challengeType || 0))
                    navigate("/checkout")
                  }}
                >
                  Start Multiverse Challenge
                </Button>
              </Box>
            </Box>

          </section>


          <WhyUs />

          <Platform />
          <Partner />
          <Affiliate />

          {/* 
          <section className="section-home-make">
            <div className="padding-global">
              <div className="container-medium">
                <div className="padding-section-large">
                  <div className="home-make-component">
                    <div id="w-node-f3bff867-0c67-3d75-5a98-97029b6e54ee-438edebb" className="home-make-header-wrapper">
                      <h2 id="w-node-f3bff867-0c67-3d75-5a98-97029b6e54ef-438edebb">Make money <span className="text-weight-xlight">while you sleep</span></h2>
                    </div>
                    <div className="spacer-large"></div>
                    <div id="w-node-f3bff867-0c67-3d75-5a98-97029b6e54f3-438edebb" className="home-make-layout">
                      <div className="home-make-item">
                        <div className="home-make-image-wrapper"><img src="/images/Home-Make-Image-12x.png" loading="lazy" width="400" sizes="(max-width: 479px) 84vw, 400px" alt="Man with laptop sitting" srcset="/images/Home-Make-Image-12x-p-500.png 500w, /images/Home-Make-Image-12x.png 800w" className="home-make-image" /><img src="/images/Home-Make-Accent-12x.png" loading="lazy" width="247" alt="Platform block screenshot" className="home-make-accent" /></div>
                        <div className="spacer-medium"></div>
                        <div className="heading-3">Curology</div>
                        <div className="spacer-small"></div>
                        <div>Launching a refreshing cosmetics brand in a crowded segment.</div>
                      </div>
                      <div className="home-make-item">
                        <div className="home-make-image-wrapper is-second"><img src="/images/Home-Make-Image-22x.png" loading="lazy" width="400" sizes="(max-width: 479px) 84vw, 400px" alt="Woman using a tablet" srcset="/images/Home-Make-Image-22x-p-500.png 500w, /images/Home-Make-Image-22x.png 800w" className="home-make-image" /><img src="/images/Home-Make-Accent-22x.png" loading="lazy" alt="Platform block screenshot" width="247" className="home-make-accent is-second" /></div>
                        <div className="spacer-medium"></div>
                        <div className="heading-3">Surface</div>
                        <div className="spacer-small"></div>
                        <div>Capturing workplace productivity in a series of candid photoshoots.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}



          {/* <section className="section-home-platform">
            <div className="padding-global">
              <div className="container-medium">
                <div className="padding-section-large">
                  <div className="home-platform-component">
                    <div id="w-node-_5d614fe0-ea1e-246a-185f-987e9e1515ab-438edebb" className="home-platform-content-wrapper">
                      <h2 id="w-node-_5d614fe0-ea1e-246a-185f-987e9e1515ac-438edebb" className="heading-1">Intuitive platform that maximizes investment potential.</h2>
                      <div className="spacer-large"></div>
                      <div className="home-platform-list">
                        <div className="home-platform-item">
                          <div className="text-weight-bold text-color-purple">AI-powered Trading</div>
                          <div className="spacer-small"></div>
                          <div>Risus sapien facicisis purus at molestie et, sed rhoncus diam. Corbi leo, pellentesque ante metus. Leo, odio vulputate at id nunc.</div>
                        </div>
                        <div className="home-platform-item">
                          <div className="text-weight-bold text-color-purple">Personalized Strategies</div>
                          <div className="spacer-small"></div>
                          <div>Tortor elit tellus elementum aliquet donec viverra metus mauris elit. Lacus adipiscing ipsum diam luctus odio aliquam.</div>
                        </div>
                      </div>
                    </div>
                    <div id="w-node-_5d614fe0-ea1e-246a-185f-987e9e1515b0-438edebb" data-w-id="5d614fe0-ea1e-246a-185f-987e9e1515b0" style={{ opacity: 1 }} className="home-platform-image-wrapper"><img src="/images/Home-Platform-Image2x.png" loading="lazy" width="513.5" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 38vw" alt="Dashboard screenshot" srcset="/images/Home-Platform-Image2x-p-500.png 500w, /images/Home-Platform-Image2x.png 1027w" className="image-block" /></div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}


          <aside className="section-cta-main"><img src="/images/Vectors-Wrapper_9.svg" loading="lazy" width="200" height="200" alt="" className="cta-glow-top" />
            <div className="padding-global">
              <div className="container-large">
                <div className="padding-section-medium">
                  <div className="cta-main-component">
                    <div id="w-node-_28d5bdcc-18a0-59e2-7dc6-bba809efe40c-09efe406" className="cta-main-heading-wrapper">
                      <h2 data-w-id="28d5bdcc-18a0-59e2-7dc6-bba809efe40d">Become a Master Trader and join the Community</h2>
                    </div>
                    <div id="w-node-_28d5bdcc-18a0-59e2-7dc6-bba809efe414-09efe406" className="spacer-medium"></div>
                    <div className="cta-main-text-wrapper">
                      <div className="cta-main-text">If you are ready to become a master trader you can start now or join our community.</div>
                    </div>
                    <div id="w-node-_4e3f4eb2-0714-360e-3fdb-fa33d29fa937-09efe406" className="spacer-medium"></div>
                    <a data-w-id="28d5bdcc-18a0-59e2-7dc6-bba809efe41a" href="https://discord.gg/4xcQ2D6F2Q" target="_blank" rel="noreferrer" className="button is-gradient-cta w-button">Join Discord</a>
                  </div>
                </div>
              </div>
            </div><img src="/images/Vectors-Wrapper_9.svg" loading="lazy" width="200" height="200" alt="" className="cta-glow-bottom" />
          </aside>


        </main>


        <div className="footer-component">
          <div className="padding-global">
            <div className="container-large">
              <div className="padding-section-medium">
                <div className="footer-top-wrapper">
                  <div className="footer-heading">Let’s chat.</div>
                  <div className="contactus">
                    <h3>Contact Us</h3>
                    <div className="textfilling">
                      <TextField
                        id="outlined-password-input"
                        label="Name"
                        type="text"
                        autoComplete="current-name"
                        className="inputEmail"
                        error={checker === true ? "error" : ""}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value)
                        }}
                      />
                      <br />
                      <TextField
                        id="outlined-password-input"
                        label="Email"
                        type="email"
                        autoComplete="current-email"
                        className="inputEmail"
                        value={email}
                        error={checker === true ? "error" : ""}
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                      />
                      <br />
                      <TextField
                        error={checker === true ? "error" : ""}
                        id="outlined-multiline-static"
                        label="Comment"
                        multiline
                        rows={4}
                        className="inputEmail"
                        value={realcomment}
                        onChange={(e) => {
                          setReaComment(e.target.value)
                          setComment(`<p>${e.target.value}<br/><a href="mailto:${email}">reply</a></p>`)
                        }}
                      /><br />
                      <div style={{ display: "flex" }}>
                        <Button className="btn-nav" variant="contained" onClick={() => {
                          var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                          if (name !== "" && email.match(validRegex) && comment !== "") {
                            setloading(true)
                            setchecker(false)
                            axios.post("https://www.multiverse-funding.com/api/sendmessage", { name, comment })
                              .then((response) => {
                                const sent = response.data.slice(0, 3);
                                console.log(sent)
                                console.log(response.status)
                                if (response.status === 200 && sent === "250") {
                                  setName("")
                                  setEmail("")
                                  setComment("")
                                  setReaComment("")
                                  handleClick()
                                  setloading(false)
                                }
                              })
                              .catch((error) => {
                                handleClickerror()
                                console.log(error);
                                setloading(false)
                              });
                          } else {
                            setchecker(true)
                          }
                        }}>  Submit</Button>{loading === true ? <CircularProgress style={{ display: "inline", marginLeft: "30px" }} color="success" /> : ""}
                      </div>


                    </div>

                  </div>
                </div>
                <div className="spacer-large"></div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Dialog

        open={openemail}
        keepMounted
        onClose={handleCloseemail}
        aria-describedby="alert-dialog-slide-description"

      >
        <div className="bgpopup">

          <DialogContent >
            <DialogContentText className="popup-contain" id="alert-dialog-slide-description">
              <img style={{ width: "300px" }} src={popup} alt="#" />
              <div
                style={{
                  position: 'absolute',
                  top: '28%',  // Positions the text 30% from the top
                  left: '50%', // Centers horizontally
                  transform: 'translate(-50%, -50%)', // Centers both horizontally and vertically
                  color: 'white',
                  padding: '5px 10px',
                  width: '300px', // Sets a fixed width
                  textAlign: 'center', // Centers text within the div
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  fontSize: '16px'
                }}
              >
                {promos.promoCode}
              </div>
              <DialogTitle className="popup-title" style={{ fontSize: "15px" }}>{promos.discountPercent}% Off until {new Date(promos.to).toLocaleDateString()}</DialogTitle>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="popup">
            <Button onClick={() => {

              handleCloseemail()
            }}>Continue</Button>
          </DialogActions>
        </div>

      </Dialog>

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          your message was sent successfully
        </Alert>
      </Snackbar>
      <Snackbar open={openerror} autoHideDuration={2000} onClose={handleCloseerror}>
        <Alert onClose={handleCloseerror} severity="error" sx={{ width: '100%' }}>
          something went wrong
        </Alert>
      </Snackbar>
    </div>);
}

export default Home;
