import "./checkout.css"
import Nav from "../navbar/Nav";
import * as React from 'react';
import Footer from "../footer/Footer"
import { Button, TextField } from "@mui/material";
import { useSelector } from 'react-redux';
import {selectsize,selectprice,selectstep,selectplatform} from '../redux/counterSlice';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import countryList from 'react-select-country-list'
import {addprice} from '../redux/counterSlice';
import {useDispatch } from 'react-redux';
import QRCode from "react-qr-code";
import axios from "axios";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinearProgress from '@mui/material/LinearProgress';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {loadStripe} from '@stripe/stripe-js';
import {Elements,} from '@stripe/react-stripe-js';
import CheckoutForm from "./Crypto/CheckoutForm";
import { useNavigate } from "react-router-dom";
function Checkout(){
    const navigate = useNavigate();
  const dispatch = useDispatch();
    //get country list
    const options = React.useMemo(() => countryList().getData(), [])
    //get acc size, price and step from home page
    const size = useSelector(selectsize);
    const price = useSelector(selectprice);
    const[GHSprice,setGHSprice]=React.useState(1)
    const step = useSelector(selectstep);
    const platform = useSelector(selectplatform);
    const [accIninitialprice,setAccIninitialprice]=React.useState()
    React.useEffect(()=>{
        setAccIninitialprice(price)
    },[])
    //discount applied
    const [discount,setDiscount]=React.useState(0)
    //discount can only run once
    const [runDiscount,setRunDiscount]=React.useState(true)
    const [RunReferralDiscount,setRunReferralDiscount]=React.useState(true)
    //dicount code input
    const [discountCode,SetDiscountCode]=React.useState("")
    const [referralCode,SetReferralCode]=React.useState("")
    //user country
    const [country, setcountry] = React.useState('');
    //first name
    const [firstName,setFirstName]=React.useState()
    //user last name
    const [LastName,setLastName]=React.useState()
    //user address
    const [pw,setpw]=React.useState()
    const [confpw,setconfpw]=React.useState()
    //user email
    const [Email,setEmail]=React.useState()
    //err
    const [err,seterr]=React.useState(false)
    //handle setting country chosen
  const handleChange = (event) => {
    setcountry(event.target.value);
  };

    const [crypto,setcrypto]=React.useState(false)
    const [StripeCard,setStripeCard]=React.useState(false)
    const [CryptoChosen,setCryptoChosen]=React.useState(false)
    //crypto type BTC/ USDT/ADA....
    const[cryptoType,setcryptoType]=React.useState("none")
    //current payment ID crypto
    const[paymentID,setPaymentID]=React.useState()
    //handle crypto change
    const handleChangeCrypto = (event) => {
        setcryptoType(event.target.value);
      };
      //crypto address to pay
    const[cryptoAddress,setCryptoAddress]=React.useState("")
    //if crypto pending
    const[pending,setpending]=React.useState(true)
    const[cardclick,setCardclick]=React.useState(false)

    const[payAmount,setpayAmount]=React.useState(true)

    const [OptionLogin,setOptionLogin]=React.useState("")
    const handleChangeOption = (event) => {
        setOptionLogin(event.target.value);
      };

    
  const[buy1,setbuy1]=React.useState(1)

  const stripePromise = loadStripe('pk_test_51OtxPlCHB8kBzeHSNh4fSqsPG9n8381Iq3OpK0XFaD0Zbbd1EihlmXAZwCwxkQhTDRQ7C2mjTCvsWuBkBROT8FJd00nKXGtSVF');

  const optionStripe = {
    mode: 'payment',
    amount: price *100,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  const[client_secret,setclient_secret]=React.useState("")
  const[payment_stripe_ID,setpayment_stripe_ID]=React.useState("")


//   const config = {
//       public_key: 'FLWPUBK-c33c9c3ef6cb2cff0f126ea6bef6ea61-X',
//       tx_ref: Date.now(),
//       amount: price,
//       currency: "USD",
//       payment_options: 'card',
//       customer: {
//         email: Email,
//         name: firstName,
//       }
//     };
//     const handleFlutterPayment = useFlutterwave(config);


const [timeLeft, setTimeLeft] = React.useState(); // 15 minutes in seconds
const startCountdown = () => {
    setTimeLeft(15 * 60); // 15 minutes in seconds
  };
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }else{
        setCardclick(false)
      }
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };


  const [promos, setPromos] = React.useState([]);
  const [alert, setAlert] = React.useState({ open: false, message: '', severity: '' });
  let URL;
  if (process.env.NODE_ENV === 'production') {
    URL = "https://www.multiverse-funding.com";
  } else {
    URL = "http://localhost:8080";
  }

  React.useEffect(() => {
    fetchPromos();
  }, []);

  const fetchPromos = async () => {
    try {
      const response = await axios.get(`${URL}/api/promos`);
      setPromos(response.data);
    } catch (error) {
      setAlert({ open: true, message: 'Error fetching promos', severity: 'error' });
    }
  };
  
    return(
        <div className="checkoutcontainer">
            <Nav/>
            {crypto===false?
            <div className="checkout-content">

                <div className="checkout-details">
                    <h2 className="checkout-title">Checkout</h2>
                    
                    <h3 style={{color:"#fff",margin: "10px"}}>Billing Details</h3>

                    <div  className="platform">
                    </div>
                    
                    <div className="details-input-checkout">
                            <div className="name-detail-checkout">
                                <TextField
                                id="outlined-password-input"
                                label="First Name"
                                type="text"
                                className="inputEmailcheckout"
                                autoComplete="current-name"
                                value={firstName}
                                onChange={(e)=>{setFirstName(e.target.value)}}
                                />
                                <TextField
                                id="outlined-password-input"
                                label="Last Name"
                                type="text"
                                className="inputEmailcheckout"
                                autoComplete="current-name"
                                value={LastName}
                                onChange={(e)=>{setLastName(e.target.value)}}
                                />
                            </div>
                            <div className="email-details-checkout">
                                <TextField
                                id="outlined-password-input"
                                label="Email"
                                type="email"
                                autoComplete="current-email"
                                className="inputEmailcheckout"
                                value={Email}
                                onChange={(e)=>{setEmail(e.target.value)}}
                                />   
                            </div>
                            <FormControlLabel style={{margin:"5px", maxWidth:"450px",color:"#FFF"}} required control={<Checkbox style={{color:"#fff"}}/>} label="I agree to the processing of my personal data according to our Term and Conditions" />
                    </div>

                    
                </div>

                <div className="Cart-checkout">
                    <h3>Your Order</h3>
                    <div>
                    <table className="table-cart">
                        <thead>
                            <tr>
                            <th>Product</th>
                            <th>Subtototal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>(${size.toLocaleString()}) {step}</td>
                            <td>${accIninitialprice}</td>
                            </tr>
                            <tr>
                                <td>{buy1!==100000?"":`GET +1 $50,000 ${step}`}</td>
                                <td>{buy1!==50000?"":`GET +1 $25,000 ${step}`}</td>
                                <td>{buy1!==25000?"":`GET +1 $10,000 ${step}`}</td>
                                <td>{buy1!==10000?"":`GET +1 $5,000 ${step}`}</td>
                            </tr>
                            <tr>
                                <td><b>{platform}</b></td>
                            </tr>
                            <tr>
                            <td><b>Discount</b></td>
                            <td><b>-{discount}%</b></td>
                            </tr>
                            <tr>
                            <td><b>Total</b></td>
                            <td><b>${price}</b></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div className="btns-cart">

                        <div className="referralcode-cart">
                            <TextField
                                id="outlined-password-input"
                                label="referral code(if any)"
                                type="text"
                                className="inputEmail"
                                value={referralCode}
                                onChange={(e)=>{SetReferralCode(e.target.value)}}
                                onBlur={()=>{
                                    axios.get("https://www.multiverse-funding.com/api/referralcode")
                                .then(event=>{
                                    // console.log(event)
                                    event.data.map(e=>{
                                        if(RunReferralDiscount && e==referralCode){
                                            if(runDiscount){
                                                setDiscount(5);
                                                dispatch(addprice(Number((95 * price) / 100).toFixed(2) || 0));
                                                setRunReferralDiscount(!RunReferralDiscount)
                                            }
                                            return
                                        }
                                    })

                                    
                                })
                                }}
                            />
                        </div>

                        <div className="coupon-cart">
                            <TextField
                                id="outlined-password-input"
                                label="coupon code(if any)"
                                type="text"
                                className="inputEmail"
                                value={discountCode}
                                onChange={(e)=>{SetDiscountCode(e.target.value)}}
                                
                            />
                            <Button className="apply-coupon applywitdth" onClick={()=>{
                                const promo = promos.find(p => p.promoCode === discountCode);
                                if(runDiscount && discountCode === "GET1"){
                                    setbuy1(size)
                                  }else if(runDiscount && promo !=undefined){
                                    if(RunReferralDiscount){
                                        setDiscount(promo.discountPercent);
                                        dispatch(addprice(Number(((100 - promo.discountPercent) * price) / 100).toFixed(2) || 0));
                                        setRunDiscount(!runDiscount)
                                    }
                                  }
                            }} variant="contained">Apply</Button>
                        </div>



                        
                        <div className="payment-btn">
                            <Button  onClick={()=>{
                                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                if(firstName!==""&& LastName !=="" && emailPattern.test(Email)  && price !==0){
                                    setcrypto(true)
                                    window.scrollTo(0, 0)
                                }else if(OptionLogin===""){
                                    seterr(true)
                                }
                            
                            }} className="apply-coupon cryptopaymentbtn" variant="contained">Pay with Crypto</Button>
                            {/* <Button  onClick={()=>{
                                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                if(firstName!==""&& LastName !=="" && emailPattern.test(Email)  && price !==0){
                                    window.scrollTo(0, 0)
                                    axios.post("https://www.multiverse-funding.com/api/create_payment_intent",{
                                        amount:price,firstName,LastName,email:Email,sku:`${step}-${Number(String(size).substring(0, String(size).length - 3))}k-${platform}`,orderId:"123"
                                    })
                                    .then((e)=>{
                                        setclient_secret(e.data.client_secret)
                                        setpayment_stripe_ID(e.data.id)
                                    })
                                    .catch(err=>{
                                        alert("something went wrong, retry or contact support")
                                    })
                                    setStripeCard(true)
                                    setcrypto(true)
                                }else if(OptionLogin===""){
                                    seterr(true)
                                }
                            
                            }} className="apply-coupon cardpaymentbtn" variant="contained">Pay with Stripe</Button> */}

                            {/* <Button onClick={()=>{
                                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                if(firstName!==""&& LastName !=="" && emailPattern.test(Email)  && price !==0){
                                        axios.post("https://www.multiverse-funding.com/api/create-payment",{
                                            LastName:LastName,
                                            firstName:firstName,
                                            sku:`${step}-${Number(String(size).substring(0, String(size).length - 3))}k-${platform}`,
                                            email:Email,
                                            amount: price,
                                            id:"223",
                                            referralCode:referralCode
                                        })
                                        .then(res=>{
                                            if(res.data.data==="success"){
                                                startCountdown()
                                                setCardclick(true)
                                                window.open('https://flutterwave.com/pay/sjtl0ilq5ves', '_blank');
                                            }
                                        })
                                        .catch(err=>{
                                            // console.log(err)
                                            alert("something went wrong")
                                        })
                                }
                            }} className="apply-coupon cardpaymentbtn" variant="contained" disabled={cardclick===true?true:false}> {cardclick===true?`Your payment expires in ${formatTime(timeLeft)}`:"Pay with Card"}</Button> */}
                            
                        </div>
                        <p style={{color:"#fff",textAlign:"center"}}>if you have any issue making the payment, kindly chat our<a href="mailto:support@multiverse-funding.com"> Support Team</a> </p>
                    </div>
                </div>
            </div>:
            StripeCard===false?
            <div>
                <div className="checkout-content">
                    <div className='crypto-contain'>
                        {CryptoChosen===false?
                        <div>
                            <p>choose a crypto currency to make the payment</p>
                        <FormControl fullWidth>
                            
                            <InputLabel id="demo-simple-select-label">Cryptocurrencies</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cryptoType}
                            label="Cryptocurrencies"
                            onChange={handleChangeCrypto}
                            >
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="BTC">BTC</MenuItem>
                            <MenuItem value="USDTTRC20">USDT TRC20</MenuItem>
                            <MenuItem value="USDTBSC">USDT BEP20</MenuItem>
                            <MenuItem value="USDTERC20">USDT ERC20</MenuItem>
                            <MenuItem value="USDC">USDC ERC20</MenuItem>
                            <MenuItem value="ADA">CARDANO (ADA)</MenuItem>
                            </Select>
                        </FormControl>
                        <Button  onClick={()=>{
                            

                            if(cryptoType!=="none"){
                                axios.post('https://api.nowpayments.io/v1/payment', {
                                        "price_amount": price,
                                        "price_currency": "usd",
                                        "pay_currency": cryptoType,
                                        "ipn_callback_url": "https://nowpayments.io",
                                      }, {
                                        headers: {
                                        'Content-Type': 'application/json',
                                        'x-api-key': '6CHAE4M-S1YMY9R-G9FQADV-9KC38X6'
                                        }
                                      }
                                    )
                                    .then(a=>{
                                        setCryptoAddress(a.data.pay_address)
                                        setpayAmount(a.data.pay_amount)
                                        setPaymentID(a.data.payment_id)
                                        setCryptoChosen(true)
                                            
                                        setInterval(function() {
                                            axios.get(`https://api.nowpayments.io/v1/payment/${a.data.payment_id}`, {
                                                headers: {
                                                'x-api-key': '6CHAE4M-S1YMY9R-G9FQADV-9KC38X6'
                                                }
                                              }
                                            )
                                            .then(a=>{
                                                // console.log(a.data.payment_status)
                                                if(a.data.payment_status==="sending" || a.data.payment_status ==="finished" || a.data.payment_status==="confirmed"){
                                                    //transaction confirmed
                                                    setpending(false)
                                                    // if referral then add his commission
                                                    if(RunReferralDiscount===false){

                                                        axios.post("https://www.multiverse-funding.com/api/refferal",{username:referralCode,refferal:{name:firstName,account:`${size.toLocaleString()} ${step} step`,commission:((9*price)/100).toFixed(1)}})
                                                            .then(a=>{
                                                                console.log(a.data.modifiedCount)
                                                            })
                                                            .catch(err=>{
                                                                alert("something went wrong")
                                                            })
                                                    }

                                                    axios.post("https://www.multiverse-funding.com/api/accountCheckout",{
                                                        firstName,
                                                        LastName,
                                                        email:Email,
                                                        sku:`${step}-${Number(String(size).substring(0, String(size).length - 3))}k`,
                                                        orderId:paymentID
                                                    })
                                                    
                                                    .then(response=>{
                                                        console.log(response)
                                                        setInterval(()=>{
                                                            window.location = "https://www.multiverse-funding.com/Dashboard";
                                                        },10000)
                                                    })
                                                    .catch(err=>{
                                                        alert("something went wrong, kindly chat support(support@multiverse-funding.com) if you havent receive your credentials")
                                                    })

                                                }
                                            })
                                        }, 60 * 1000);

                                        
                                    })
                                    .catch(err=>{
                                        console.log(err)
                                        alert("something went wrong")
                                    })
                            }       
                            }} className="apply-coupon cryptopaymentbtn" style={{margin:"10px 0px"}} variant="contained">PAY NOW</Button>
                            
                        </div>:
                        <div>
                          <div className="user-details">
                            <p>Email: {Email}</p>
                            <p>payment ID: {paymentID}</p>
                            <p> ${size.toLocaleString()} {step} Challenge</p>
                            <p>send <b>{payAmount}</b><b>{cryptoType==="USDTTRC20"?"USDT TRC20 Network":""}</b> <b>{cryptoType==="USDC"?"USDC ERC20 Network":""}</b> <b>{cryptoType==="BTC"?"BTC Network":""}</b> <b>{cryptoType==="USDTBSC"?"USDT BEP20 Network":""}</b> <b>{cryptoType==="USDTERC20"?"USDT ERC20 Network":""}</b><b>{cryptoType==="ADA"?"ADA Network":""}</b> to the address below and wait for transaction to complete</p>
                        </div>
                        
                        <div className="address-container">
                        <TextField  value={cryptoAddress}  className='input-crypto-address' type="text" label="Crypto address" variant="outlined" />
                        <Button className="copy-btn-address" variant="contained" onClick={() => {
                            if (navigator.clipboard) {
                            navigator.clipboard.writeText(cryptoAddress).then(
                            function() {
                                console.log("Text copied to clipboard successfully!");
                            },
                            function(err) {
                                console.error("Failed to copy text: ", err);
                            }
                            );
                        } else {
                            // Fallback code for unsupported browsers
                        }}}>Copy
                            <ContentCopyIcon/>
                        </Button>
                        </div>
                        <div className="QR-code">
                            <QRCode style={{width:"200px", height:"200px"}} value={cryptoAddress} />
                        </div>
                        <div className="pending-payment-crypto">
                            <p style={{textAlign:"center"}}>{pending===true?"pending for payment":"Payment Completed"} </p>
                            {pending===true?<LinearProgress />:<TaskAltIcon style={{textAlign:"center",marginLeft:"90px"}}/>}
                        </div>   
                        </div>}
                       
                    </div>
                </div>
                </div>:
                <div>
                        <Elements stripe={stripePromise} options={optionStripe}>
                            <CheckoutForm id={payment_stripe_ID} client_secret={client_secret}/>
                        </Elements>

                </div>}
            <Footer/>
        </div>
    )
}
export default Checkout;


//flutterwave integration

                                    // handleFlutterPayment({
                                    //     callback: (response) => {
                                    //        if(response.status=='successful'){
                                    //         //give referral his commission
                                    //         if(RunReferralDiscount===false){

                                    //             axios.post("https://www.multiverse-funding.com/api/refferal",{username:referralCode,refferal:{name:firstName,account:`${size.toLocaleString()} ${step} step`,commission:((9*price)/100).toFixed(1)}})
                                    //                 .then(a=>{
                                    //                     console.log(a.data.modifiedCount)
                                    //                 })
                                    //                 .catch(err=>{
                                    //                     alert("something went wrong")
                                    //                 })
                                    //         }
                                    //         //create account to trader
                                    //         axios.post("https://www.multiverse-funding.com/api/createMetaTraderAccount",{
                                    //                     firstName,
                                    //                     LastName,
                                    //                     email:Email,
                                    //                     sku:`${step}-${Number(String(size).substring(0, String(size).length - 3))}k-${platform}`,
                                    //                     orderId:paymentID
                                    //                 })
                                    //                 .then(response=>{
                                    //                     console.log(response)
                                    //                     if(response.data.msg.payload.successMessage==="Resource.TradeAccount_has_been_created_successfully"){
                                    //                         alert("your trading account was created successfully")
                                    //                     }
                                    //                 })
                                    //                 .catch(err=>{
                                    //                     // console.log(err)
                                    //                     alert("something went wrong, kindly chat support(support@multiverse-funding.com) if you havent receive your credentials")
                                    //                 })


                                    //        }
                                           
                            
                                    //        setTimeout(() => {
                                    //         closePaymentModal() // this will close the modal programmatically
                                            
                                    //        }, 5000);
                                    //     },
                                    //     onClose: () => {},
                                    //   });