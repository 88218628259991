import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Box, Typography } from '@mui/material';
import axios from 'axios';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
const Certificate = () => {
    const [loading, setLoading] = useState(false);
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
        // URL = "https://www.multiverse-funding.com";
    }
    const [email, setEmail] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [FirstName, setFirstName] = useState("")
    const [LastName, setLastName] = useState("")
    useEffect(() => {
        const timer = setTimeout(() => {
            if (email) {
                axios.post(`${URL}/api/search`, { email }) // POST request with email in body
                    .then(response => {
                        setUserOptions(response.data);
                        if (response.data.length === 1) {
                            setSelectedUser(response.data[0].email);
                            setLastName(response.data[0].lastName)
                            setFirstName(response.data[0].firstName)
                        } else {
                            setSelectedUser('');
                        }
                    })
                    .catch(error => console.error('Error fetching users:', error));
            }
        }, 500); // Debounce time is 500ms

        return () => clearTimeout(timer);
    }, [email]); // Effect runs whenever the email state changes

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedUser !== "" && FirstName !== "" && LastName !== "") {
            axios.post(`${URL}/api/create-certificate`, { Email: selectedUser, FirstName: FirstName, LastName: LastName, PdfCopyToEmail: "support@multiverse-funding.com", ProgramName: "1 Step", AccountSize: 100000 })
                .then(response => {
                    if (response.data.success) {
                        alert(response.data.content)
                    }


                })
                .catch(error => {
                    console.error('Error creating account:', error);
                    alert('Failed to create account.');
                });
        }

    };
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        accountSize: '',
        pdfCopyToEmail: 'support@multiverse-funding.com',
        commission: ''
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit2 = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${URL}/api/create-payout-certificate`, { fullname: formData.fullname, Email: formData.email, AccountSize: Number(formData.accountSize), Commission: Number(formData.commission), PdfCopyToEmail: formData.pdfCopyToEmail });
            setSnackbar({
                open: true,
                message: 'certificate successfully!',
                severity: 'success'
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'submission failed!',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    return (
        <div>
            <Box style={{ marginTop: "50px" }} sx={{ maxWidth: 300, margin: "0 auto" }}>
                <Typography variant="h5" gutterBottom>
                    Create 1 Step evaluation Certificate
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                {userOptions.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="user-label">Select User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user-select"
                            value={selectedUser}
                            label="Select User"
                            onChange={e => setSelectedUser(e.target.value)}
                        >
                            {userOptions.map((user) => (
                                <MenuItem key={user._id} value={user.email}>
                                    {user.firstName + " " + user.lastName || user.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                >
                    Send Certificate
                </Button>
            </Box>
            <Box component="form" onSubmit={handleSubmit2} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 300, margin: "0 auto" }}>
                <Typography variant="h5" gutterBottom>
                    Payout Certificate
                </Typography>
                <TextField
                    label="Full Name"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Account Size"
                    name="accountSize"
                    value={formData.accountSize}
                    onChange={handleChange}
                    required
                    type='number'
                />
                <TextField
                    label="Commission"
                    name="commission"
                    value={formData.commission}
                    onChange={handleChange}
                    required
                    type='number'
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    {loading ? <CircularProgress size={24} /> : 'SEND PAYOUT Certificate'}
                </Button>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Box>
        </div>

    );
};

export default Certificate;
