import React, { useState, useEffect } from 'react';
import './style.css'
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Localbase from 'localbase'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Input } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import TradingDaysProgress from './TradingDaysProgress'
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Snackbar from '@mui/material/Snackbar';
const Overview = ({ receivedData }) => {


    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "https://www.multiverse-funding.com";
        //URL = "http://localhost:8080";
    }
    let db = new Localbase('Mfundingdashboard')
    const [CurrentAccount, setCurrentAccount] = useState("")
    const [Details, setDetails] = useState(null)
    const [AccountDetails, setAccountDetails] = useState(null)
    const [plans, setPlans] = React.useState([])
    useEffect(() => {
        axios.get(`${URL}/api/get-plans`)
            .then(response => {
                setPlans(response.data.plan);
            })
            .catch(error => console.error('Error fetching plans:', error));
    }, []);

    function CircularProgressWithLabel({ value, filledColor, trackColor }) {
        return (
            <Box style={{ marginTop: "20px" }} position="relative" display="inline-flex" alignItems="center" justifyContent="center">
                {/* Track as background with 100% progress */}
                <CircularProgress
                    variant="determinate"
                    value={100}
                    sx={{
                        color: trackColor, // Track color (unfilled part)
                        position: 'absolute',
                        zIndex: 1,
                    }}
                    size={80}
                    style={{ marginTop: "20px" }}
                />
                {/* Actual progress */}
                <CircularProgress
                    variant="determinate"
                    value={value}
                    size={80}
                    sx={{
                        color: filledColor, // Filled part color
                        position: 'absolute',
                        zIndex: 2,
                    }}
                    style={{ marginTop: "20px" }}
                />
                <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    zIndex={3} // Ensure the text is above both CircularProgress components
                >
                    <Typography style={{ fontSize: "20px", color: "rgba(12, 11, 11, 1)", marginTop: "20px" }} variant="caption" component="div" color="text.secondary">
                        {`${value.toFixed(1)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }
    const filledColor = 'blue'; // Green for filled part
    const trackColor = 'rgba(217, 217, 217, 1)'; // White for the track (unfilled part)

    useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length !== 0) {
                axios.post(`${URL}/api/find-user-by-email-for-id`, { email: users[0].email })
                    .then(res => {
                        setAccountDetails(res.data[0])
                    })
                    .catch(err => {
                        console.log(err)
                        setAccountDetails(users[0])
                    })
                    console.log(AccountDetails)
            }
        })
    }, []);

    useEffect(() => {
        if (CurrentAccount !== "") {
            axios.post(`${URL}/api/find-trading-acccount`, {
                accountId: CurrentAccount
            })
                .then(res => {
                    setDetails(res.data)
                })
                .catch(err => {
                    console.log(CurrentAccount)
                    console.log(err)
                })
        }

    }, [CurrentAccount]);

    function formatCurrency(price, currency = 'USD', locale = 'en-US') {
        return price.toLocaleString(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2, // Ensures the decimal part is always shown
        });
    }
    const status = {
        Active: "darkgreen",
        Passed: "darkblue",
        Violated: "darkred"

    }

    //Loss & Win percentage calculator
    const [target, setTarget] = useState(null)
    const [target_percentage, settarget_percentage] = useState(0)
    const [overall_percentage, setoverall_percentage] = useState(0)
    const [daily_percentage, setdaily_percentage] = useState(0)
    const [dailyLoss, setdailyLoss] = useState(null)
    const [overallLoss, setOverallLoss] = useState(null)
    const [minimumTradingDays, setminimumTradingDays] = useState(null)
    const [totalDays, settotalDays] = useState(null)
    const [CurrentMinimumTradingDays, setCurrentMinimumTradingDays] = useState(null)
    const [activeDays, setactiveDays] = useState(null)
    useEffect(() => {
        if (Details !== null && plans.length > 0) {
            setCurrentMinimumTradingDays(countTradingDays(Details.tradingHistory))
            setactiveDays(daysFromOldestTrade(Details.tradingHistory))
            const foundAccountType = plans.find(account => {
                const foundStage = account.stages.find(stage => stage.stageName === Details.accountType)
                if (foundStage) {
                    setminimumTradingDays(Number(foundStage.minimumTradingDays))
                    settotalDays(Number(foundStage.totalDays))
                    calculateProgress(Details.InitialBalance, Details.CurrentBalance, Number(foundStage.profitTarget), Number(foundStage.dailyDrawdown), Number(foundStage.overallDrawdown))
                }
            });


        }

    }, [Details]);


    function countTradingDays(trades) {
        const tradingDays = new Set(); // Create a Set to hold unique trading days

        // Loop through each trade
        trades.forEach(trade => {
            const date = new Date(trade.timestamp); // Convert the timestamp to a Date object
            const dateString = date.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
            tradingDays.add(dateString); // Add the date string to the Set
        });

        return tradingDays.size; // The size of the Set is the number of unique trading days
    }

    function daysFromOldestTrade(trades) {
        // Return 0 immediately if there are no trades in the list
        if (trades.length === 0) {
            return 0;
        }

        let oldestDate = new Date(trades[0].timestamp); // Assume the first trade is the oldest initially

        // Loop through the trades to find the oldest
        trades.forEach(trade => {
            const tradeDate = new Date(trade.timestamp);
            if (tradeDate < oldestDate) {
                oldestDate = tradeDate; // Update oldestDate if an older date is found
            }
        });

        const today = new Date(); // Today's date
        const timeDifference = today - oldestDate; // Difference in milliseconds
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days

        return daysDifference;
    }

    function calculateProgress(initialAccountSize, currentAccountSize, targetGrowthPercentage, dailyDrawdown, overallDrawdown) {
        // Calculate the target account size, which is (100% + target growth percentage) of the initial account size
        const targetAccountSize = initialAccountSize * (1 + targetGrowthPercentage);
        const targetSize = targetAccountSize - initialAccountSize;
        const dailyLoss = initialAccountSize * dailyDrawdown;
        const OverallLoss = initialAccountSize * overallDrawdown;
        setTarget(targetSize)
        setdailyLoss(dailyLoss)
        setOverallLoss(OverallLoss)
        settarget_percentage(calculate_Target_Progress(currentAccountSize, initialAccountSize, (targetSize + initialAccountSize)))
        setdaily_percentage(calculateDrawdown(currentAccountSize, initialAccountSize, (initialAccountSize - targetSize)))
        setoverall_percentage(calculateDrawdown(currentAccountSize, initialAccountSize, (initialAccountSize - targetSize)))
    }

    function calculateDrawdown(currentBalance, initialBalance, totalDrawdown) {
        const actualLoss = initialBalance - currentBalance;
        const drawdownPercentage = (actualLoss / totalDrawdown) * 1000;

        if (actualLoss <= 0) {
            return 0; // No loss or balance is greater than initial means 0% drawdown
        } else if (drawdownPercentage >= 100) {
            return 100; // Cap the drawdown at 100%
        } else {
            return drawdownPercentage; // Returns the drawdown percentage calculated
        }
    }
    function calculate_Target_Progress(currentBalance, initialAccountSize, targetBalance) {
        if (currentBalance <= initialAccountSize) {
            return 0;
        } else if (currentBalance >= targetBalance) {

            return 100;
        } else {
            return ((currentBalance - initialAccountSize) / (targetBalance - initialAccountSize)) * 100;
        }
    }


    // Function to calculate the average loss, excluding positions where profit is null or undefined
    function calculateAverageLoss(accounts) {
        const validAccounts = accounts.filter(account => account.profit !== null && account.profit !== undefined && account.profit < 0);
        if (validAccounts.length === 0) return 0;
        const totalLoss = validAccounts.reduce((acc, curr) => acc + Math.abs(curr.profit), 0);
        return Math.round(totalLoss / validAccounts.length);
    }

    // Function to calculate the average win, excluding positions where profit is null or undefined
    function calculateAverageWin(accounts) {
        const validAccounts = accounts.filter(account => account.profit !== null && account.profit !== undefined && account.profit > 0);
        if (validAccounts.length === 0) return 0;
        const totalWin = validAccounts.reduce((acc, curr) => acc + curr.profit, 0);
        return Math.round(totalWin / validAccounts.length);
    }

    // Function to calculate the win rate, excluding positions where profit is null or undefined
    function calculateWinRate(accounts) {
        const validAccounts = accounts.filter(account => account.profit !== null && account.profit !== undefined);
        const totalTrades = validAccounts.length;
        if (totalTrades === 0) return 0;
        const winningTrades = validAccounts.filter(account => account.profit > 0).length;
        return Math.round((winningTrades / totalTrades) * 100);
    }

    // Function to calculate the total lot size
    function calculateTotalLotSize(accounts) {
        const validAccounts = accounts.filter(account => account.lot !== null && account.lot !== undefined);
        if (validAccounts.length === 0) return 0;
        const totalLotSize = validAccounts.reduce((acc, curr) => acc + curr.lot, 0);
        return totalLotSize;
    }


    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleClick = () => {
        if (Details !== null && Details.accountStatus == "Active") {
            axios.post(`${URL}/api/upgrade`, { accountId: Details.accountId, planId: Details.PlanId, email: Details.accountEmail })
                .then(response => {
                    console.log(response)
                    setMessage('Upgrade Successful!');
                    setOpen(true);
                })
                .catch(error => {
                    console.log(error)
                    setMessage(error.response.data.message);
                    setOpen(true);
                });
        }

    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const textStyle = {
        fontFamily: 'Cabin, sans-serif',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '35px',
        textAlign: 'center',
    };
    function convertToHumanReadable(timestamp) {
        const date = new Date(timestamp);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };

        return date.toLocaleDateString('en-US', options);
    }

    useEffect(() => {
        if (receivedData._id) {
            setCurrentAccount(receivedData.accountId)
        }
    }, [receivedData])

    return (
        <div className='overview-container'>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap' }} sx={{ width: '99vw', margin: 2, height: 180 }}>
                <div>
                    <FormControl variant="standard">
                        Account Number
                        {AccountDetails !== null ?
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={CurrentAccount}
                                onChange={(e) => { 
                                    setCurrentAccount(e.target.value) 
                                    console.log(e.target.value)
                                }}
                                label="Select Currency Pair"
                                input={<Input disableUnderline />}
                            >
                                {AccountDetails.accountIDs.map((id, index) => (
                                    <MenuItem key={index} value={id}>({id}) </MenuItem>
                                ))}

                            </Select>
                            : ""}
                        {Details !== null ? formatCurrency(Details.accountType) : ""}
                    </FormControl>
                    <div>
                        {Details !== null ? <Chip
                            sx={{
                                backgroundColor: status[Details.accountStatus], // You can use any color value here.
                                color: 'white', // Sets the text color to white for better contrast.
                                '&:hover': {
                                    backgroundColor: status[Details.accountStatus], // Optional: change color on hover.
                                },
                            }}

                            label={Details.accountStatus} /> : ""}


                    </div>
                </div>

                <Button
                    sx={{
                        backgroundColor: 'rgba(15, 10, 70, 1)',
                        '&:hover': {
                            backgroundColor: 'rgba(15, 10, 70, 0.85)', // Optional: Darker shade on hover
                        },
                    }}
                    variant="contained"
                    startIcon={<UpgradeIcon />}
                    onClick={handleClick}
                >
                    Upgrade to Next Phase
                </Button>
            </Card>
            <div className='all-card-overview'>
                <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 350, height: 180, margin: 2 }}>
                    <CardContent>
                        <Typography color="text.secondary">
                            <div style={textStyle} className='first_Container'>
                                <ul>
                                    <li>Balance</li>
                                    <li>Equity</li>
                                    <li>No. Of Trades</li>
                                    <li>Total lot</li>
                                </ul>
                                <ul>
                                    <li>{Details !== null ? formatCurrency(Details.CurrentBalance) : "0.00"}</li>
                                    <li>{Details !== null ? formatCurrency(Details.equity) : "0.00"}</li>
                                    <li>{Details !== null ? Details.tradingHistory.length : "0"}</li>
                                    <li>{Details !== null ? calculateTotalLotSize(Details.tradingHistory).toFixed(2) : 0}</li>
                                </ul>
                            </div>
                        </Typography>
                    </CardContent>
                </Card>
                <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 350, margin: 2, height: 180 }}>
                    <div className='title_target_loss'>
                        <div>
                            <p style={textStyle}>Profit Target</p><br />
                            <CircularProgressWithLabel value={target_percentage} filledColor={filledColor} trackColor={trackColor} />
                            <p style={{ position: "relative", top: "40px" }}>{Details !== null ? formatCurrency(Number(target)) : ""}</p>
                        </div>
                        <div>
                            <p style={textStyle}>Daily Loss</p><br />
                            <CircularProgressWithLabel value={daily_percentage} filledColor={"yellow"} trackColor={trackColor} />
                            <p style={{ position: "relative", top: "40px" }}>{Details !== null ? formatCurrency(Number(dailyLoss)) : ""}</p>
                        </div>
                        <div>
                            <p style={textStyle}>Max Loss</p><br />
                            <CircularProgressWithLabel value={overall_percentage} filledColor={"red"} trackColor={trackColor} />
                            <p style={{ position: "relative", top: "40px" }}>{Details !== null ? formatCurrency(Number(overallLoss)) : ""}</p>
                        </div>
                    </div>
                </Card>
                <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 350, height: 180, margin: 2 }}>
                    <CardContent className='contain-win-loss'>
                        <div style={textStyle}>
                            <p>Average Win</p>
                            <p style={{ fontSize: 30, color: "darkblue" }}>{Details !== null ? calculateAverageWin(Details.tradingHistory) : 0}</p>
                        </div>
                        <div style={textStyle}>
                            <p>Average Loss</p>
                            <p style={{ fontSize: 30, color: "darkred" }}>-{Details !== null ? calculateAverageLoss(Details.tradingHistory) : 0}</p>
                        </div>
                    </CardContent>
                </Card>
                <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 150, margin: 2, height: 180 }}>
                    <CardContent>
                        <Typography style={textStyle}>
                            <p className='titleBox'>Win Rate</p>
                            <p className='winrate_num'>{Details !== null ? calculateWinRate(Details.tradingHistory) : 0}<sup style={{ fontSize: "20px" }}>%</sup></p>
                        </Typography>
                    </CardContent>
                </Card>

            </div>


            <TradingDaysProgress activeDays={activeDays} totalDays={totalDays} MinimumTradingdays={minimumTradingDays} CurrentMinimumTradingDays={CurrentMinimumTradingDays} />
            <div style={{ padding: "0px 10px" }}>
                <TableContainer component={Paper} style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="trade history table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Position ID</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Symbol</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Type</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Entry Price</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Closing Price</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>opening time</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>closing time</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Lot</TableCell>
                                <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>Profit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Details !== null && Details.tradingHistory.map((trade) => (
                                <TableRow
                                    key={trade.positionId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: 'rgba(12, 11, 11, 1)' }}
                                >
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.positionId}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.symbol}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.action}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.entryPrice}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.closingPrice}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{convertToHumanReadable(trade.timestamp)}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{convertToHumanReadable(trade.closingTime)}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.lot}</TableCell>
                                    <TableCell sx={{ color: 'rgba(12, 11, 11, 1)' }}>{trade.profit}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message={message}
                />
            </div>
        </div>
    );
};

export default Overview;


