import { useEffect, useState } from 'react';
import Nav from "../navbar/Nav";
import { Grid, Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import Footer from "../footer/Footer";
import axios from 'axios'
import { Box, TextField, Alert } from "@mui/material";
function PartnersShip() {
    const [partners, setPartners] = useState([]);
    const [alert, setAlert] = useState({ open: false, message: "", severity: "" });

    let URL;
    if (process.env.NODE_ENV === "production") {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
    }

    useEffect(() => {
        fetchPartners();
    }, []);

    const fetchPartners = async () => {
        try {
            const response = await axios.get(`${URL}/api/partners`);
            console.log(response.data)
            setPartners(response.data);
        } catch (error) {
            setAlert({ open: true, message: "Error fetching partners", severity: "error" });
        }
    };

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        socialLink: "", // Added social media/website link field
    });

    const [formStatus, setFormStatus] = useState({
        success: null, // null for no message, true for success, false for error
        message: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        // Simple validation
        if (!formData.name || !formData.email || !formData.message || !formData.socialLink) {
            setFormStatus({
                success: false,
                message: "Please fill out all fields before submitting.",
            });
            return;
        }

        try {
            // Send form data to the backend API
            const response = await axios.post(`${URL}/api/get-in-touch`, formData);
            setFormStatus({
                success: true,
                message: response.data.message || "Your submission has been received!",
            });

            // Clear form fields
            setFormData({ name: "", email: "", message: "", socialLink: "" });
        } catch (error) {
            setFormStatus({
                success: false,
                message:
                    error.response?.data?.message || "An error occurred. Please try again.",
            });
        }

    };
    return (
        <div>
            <Nav />
            <main class="main-wrapper">
                <header class="section-contact-header">
                    <Grid
                        container
                        spacing={3}
                        justifyContent="center"
                        style={{ padding: "20px", maxWidth: "100%", marginTop: "50px" }}
                    >
                        {partners.map((partner, index) => (
                            <Grid item xs={6} sm={4} md={3} key={index} style={{ display: "flex", justifyContent: "center" }}>
                                <Card
                                    style={{
                                        width: "250px", // Fixed card width
                                        borderRadius: "10px",
                                        border: "1px solid #8e44ad",
                                        color: "#ffffff",
                                        backgroundColor: "transparent"
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={partner.image}
                                        alt={partner.businessName}
                                        style={{ objectFit: "contain", padding: "10px" }}
                                    />
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }}
                                        >
                                            {partner.businessName}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            href={partner.link}
                                            target="_blank"
                                            sx={{
                                                mt: 2,
                                                backgroundColor: "#0c664f",
                                                color: "white",
                                                "&:hover": {
                                                    backgroundColor: "#08573e", // Darker shade for hover effect
                                                },
                                                "&:active": {
                                                    backgroundColor: "#0c664f",
                                                    color: "white",
                                                },
                                                "&:focus": {
                                                    outline: "none", // Optional: Remove focus outline
                                                },
                                                textAlign: "center"
                                            }}
                                        >
                                            Visit
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <div class="nav-spacer"></div>
                    <div class="padding-global">
                        <div class="container-large">
                            <div class="padding-section-large">
                                <div data-w-id="db10c178-4bfe-ba0d-25dd-efdf2dd2f9dd" class="contact-header-component">
                                    <div id="w-node-db10c178-4bfe-ba0d-25dd-efdf2dd2f9de-438edec2" data-w-id="db10c178-4bfe-ba0d-25dd-efdf2dd2f9de" class="contact-header-content-wrapper">
                                        <div class="subheading">contact us</div>
                                        <div class="spacer-medium"></div>
                                        <h1 id="w-node-db10c178-4bfe-ba0d-25dd-efdf2dd2f9df-438edec2">BECOME OUR PARTNER TODAY</h1>
                                    </div>
                                    <div id="w-node-_7ab06fa6-74d0-3cb9-4bd0-bb54371a8b4b-438edec2" data-w-id="7ab06fa6-74d0-3cb9-4bd0-bb54371a8b4b" class="contact-form-wrapper">
                                        <div class="contact-form-heading-wrapper">
                                            <div class="small-caps">sign up now</div>
                                        </div>
                                        <Box
                                            sx={{
                                                maxWidth: "500px",
                                                mx: "auto",
                                                p: 3,
                                                backgroundColor: "#1a1a1a", // Dark background color
                                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
                                                borderRadius: "8px",
                                                color: "white",
                                            }}
                                        >

                                            <form onSubmit={handleSubmit}>
                                                <Box mb={3}>
                                                    <label for="name" class="contact-field-name">Business Name</label>
                                                    <input value={formData.name} onChange={handleInputChange} class="contact-form-input w-input" maxlength="256" name="name" data-name="name" placeholder="" type="text" id="name" required="" />
                                                </Box>
                                                <Box mb={3}>
                                                    <label for="email" class="contact-field-name">Email Address</label>
                                                    <input value={formData.email} onChange={handleInputChange} class="contact-form-input w-input" maxlength="256" name="email" data-name="Email" placeholder="" type="email" id="email" required="" />
                                                </Box>

                                                <Box mb={3}>
                                                    <label for="message" class="contact-field-name">how will you promote us</label>
                                                    <textarea id="Message" value={formData.message} onChange={handleInputChange} name="message" maxlength="5000" data-name="Message" placeholder="" required="" class="contact-form-area w-input"></textarea>
                                                </Box>
                                                <Box mb={3}>
                                                    <label for="socialLink" class="contact-field-name">website/social media link</label>
                                                    <input value={formData.socialLink} onChange={handleInputChange} class="contact-form-input w-input" maxlength="256" name="socialLink" data-name="Email" placeholder="" type="text" id="email" required="" />
                                                </Box>
                                                <Box mb={2}>
                                                    {/* <input type="submit" data-wait="Please wait..." class="button is-contact w-button" value="Submit Form" /> */}
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        sx={{
                                                            backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)", // Gradient button
                                                            color: "white",
                                                            fontWeight: "bold",
                                                            "&:hover": {
                                                                opacity: 0.9,
                                                            },
                                                        }}
                                                    >
                                                        Submit Form
                                                    </Button>
                                                </Box>
                                            </form>
                                            {formStatus.success !== null && (
                                                <Alert
                                                    severity={formStatus.success ? "success" : "error"}
                                                    onClose={() => setFormStatus({ success: null, message: "" })}
                                                    sx={{ mt: 2 }}
                                                >
                                                    {formStatus.message}
                                                </Alert>
                                            )}
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                align="center"
                                                mt={2}
                                                sx={{ color: "white" }}
                                            >
                                                * We don’t share your data with anyone.
                                            </Typography>
                                        </Box>
                                        <img src="images/Vectors-Wrapper_9.svg" loading="lazy" alt="" class="contact-form-accent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-header-background"></div>
                </header>
            </main>
            <Footer />
        </div>
    )
}
export default PartnersShip;
