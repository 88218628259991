import * as React from 'react';
import usIcon from "../img/us.png";
import Button from '@mui/material/Button';
function Affiliate() {
    return (
        <section id="features" class="section-home-feature">
            <div class="padding-global">
                <div class="container-medium">
                    <div class="padding-section-large">
                        <div class="home-feature-component">
                            <div id="w-node-_7dacd93b-a131-7011-23ef-71f2bb96d242-438edebb" class="home-feature-content-wrapper">
                                <h2 id="w-node-_7dacd93b-a131-7011-23ef-71f2bb96d243-438edebb" class="heading-1">Affiliate Program</h2>
                                <div class="spacer-small"></div>
                                <div class="spacer-large"></div>
                                <div class="home-feature-item">
                                    <div class="text-weight-bold">10% Commission</div>
                                    <div class="spacer-xsmall"></div>
                                    <div>Invite friends to Multiverse Funding using your unique code and earn 10% commission on their challenge fees. Request payouts once your commissions reach $100!</div>
                                </div>
                                <div class="spacer-large"></div>
                                <div class="home-feature-item">
                                    <div class="text-weight-bold">5% Discount</div>
                                    <div class="spacer-xsmall"></div>
                                    <div>Customize your trading preferences by setting the desired percentage thresholds for buying and selling your favorite stocks.</div>
                                </div>
                                <a target="_blank" rel="noreferrer" href="https://www.multiverse-funding.com/affiliate"><Button variant="contained"
                                    size="large"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#0c664f",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "#08573e", // Darker shade for hover effect
                                        },
                                        "&:active": {
                                            backgroundColor: "#0c664f",
                                            color: "white",
                                        },
                                        "&:focus": {
                                            outline: "none", // Optional: Remove focus outline
                                        },
                                    }}> Join Affiliate Program</Button></a>

                            </div>
                            <div id="w-node-_7dacd93b-a131-7011-23ef-71f2bb96d247-438edebb" class="home-feature-image-wrapper"><img src={usIcon} loading="lazy" width="287" style={{ opacity: 1 }} alt="Platform gauge screenshot" data-w-id="7dacd93b-a131-7011-23ef-71f2bb96d248" class="home-feature-image" />
                                <div data-w-id="7a58e8ee-59c1-a836-9947-1aff9f70ead9" style={{ opacity: 1 }} class="home-feature-glow"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Affiliate;
