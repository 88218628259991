
import './faq.css'
import Nav from "../navbar/Nav";
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { general,OneStep,TwoStep } from './rules';
import Footer from "../footer/Footer"
function FAQ(){
    return(
        <div>

            <div className="faq-container">
            <Nav/>
                <div className="step-container">
                <h1>General FAQ</h1>
                {general.map(item=>
                    <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography><b>{item.title}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {item.description!==""?item.description.map(e=><p>{e}</p>):""}
                        {item.list!==""?item.list.map(e=><div>
                            <li>{e}</li><br/>
                            </div>):""}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                )}
                </div>
                
                <div className="step-container">
                    <h1>1 STEP HFT series FAQ</h1>
                    {OneStep.map(item=>
                    <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography><b>{item.title}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {item.description!==""?item.description.map(e=><p>{e}</p>):""}
                        {item.list!==""?item.list.map(e=><div>
                            <li>{e}</li><br/>
                            </div>):""}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                )}
                </div>

                <div className="step-container">
                    <h1>2 STEP FAQ</h1>
                    {TwoStep.map(item=>
                    <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography><b>{item.title}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {item.description!==""?item.description.map(e=><p>{e}</p>):""}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                )}
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default FAQ;
