import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

export default function Promo() {
    const [promoCode, setPromoCode] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [discountPercent, setDiscountPercent] = useState('');
    const [promos, setPromos] = useState([]);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });

    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
    }

    useEffect(() => {
        fetchPromos();
    }, []);

    const fetchPromos = async () => {
        try {
            const response = await axios.get(`${URL}/api/promos`);
            setPromos(response.data);
        } catch (error) {
            setAlert({ open: true, message: 'Error fetching promos', severity: 'error' });
        }
    };

    const handleAddPromo = async () => {
        try {
            const newPromo = { promoCode, from, to, discountPercent };
            await axios.post(`${URL}/api/promos`, newPromo);
            setAlert({ open: true, message: 'Promo added successfully', severity: 'success' });
            fetchPromos();
            setPromoCode('');
            setFrom('');
            setTo('');
            setDiscountPercent('');
        } catch (error) {
            setAlert({ open: true, message: 'Error adding promo', severity: 'error' });
        }
    };

    const handleDeletePromo = async (id) => {
        try {
            await axios.delete(`${URL}/api/promos/${id}`);
            setAlert({ open: true, message: 'Promo deleted successfully', severity: 'success' });
            fetchPromos();
        } catch (error) {
            setAlert({ open: true, message: 'Error deleting promo', severity: 'error' });
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 600,
                mx: 'auto',
                p: 3,
                mt: 4,
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)'
            }}
        >
            <Typography variant="h5" gutterBottom>Manage Promo Codes</Typography>

            {/* Add Promo Form */}
            <Box component="form" noValidate autoComplete="off" sx={{ mb: 4 }}>
                <TextField
                    label="Promo Code"
                    fullWidth
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Discount Percentage"
                    type="number"
                    fullWidth
                    value={discountPercent}
                    onChange={(e) => setDiscountPercent(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <Button variant="contained" color="primary" onClick={handleAddPromo}>Add Promo</Button>
            </Box>

            {/* Promo Codes Table */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Promo Code</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Discount (%)</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {promos.map((promo) => (
                            <TableRow key={promo._id}>
                                <TableCell>{promo.promoCode}</TableCell>
                                <TableCell>{new Date(promo.from).toLocaleDateString()}</TableCell>
                                <TableCell>{new Date(promo.to).toLocaleDateString()}</TableCell>
                                <TableCell>{promo.discountPercent}</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => handleDeletePromo(promo._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Alert Snackbar */}
            <Snackbar
                open={alert.open}
                autoHideDuration={4000}
                onClose={() => setAlert({ ...alert, open: false })}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
