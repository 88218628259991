import React, { useState } from 'react';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { Snackbar, Alert } from '@mui/material';

export default function Position() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    let URL;
    if (process.env.NODE_ENV == 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        // URL = "http://localhost:8080";
        URL = "https://www.multiverse-funding.com";
    }
    const [formData, setFormData] = useState({
        symbol: '',
        takeProfit: '',
        stopLoss: '',
        action: '',
        lot: '',
        accountId: '',
        entryPrice: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        const numberFields = ['takeProfit', 'stopLoss', 'lot', 'entryPrice'];
        setFormData(prevState => ({
            ...prevState,
            [name]: numberFields.includes(name) ? parseFloat(value) : value
        }));
    };

    const handleSubmit = async () => {
        console.log("submitting")
        try {
            const response = await axios.post(`${URL}/api/add-position`, formData);
            console.log('Response:', response);
            setSnackbarMessage('Position submitted successfully!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error submitting position:', error);
            setSnackbarMessage('Failed to submit position.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };


    return (
        <Box
            sx={{
                maxWidth: 500,
                mx: 'auto', // centers the box
                p: 3,
                mt: 4,
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)'
            }}
        >
            <div>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Symbol"
                    variant="outlined"
                    name="symbol"
                    value={formData.symbol}
                    onChange={handleChange}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Lot"
                    variant="outlined"
                    name="lot"
                    value={formData.lot}
                    type="number"
                    onChange={handleChange}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Account ID"
                    variant="outlined"
                    name="accountId"
                    value={formData.accountId}
                    onChange={handleChange}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Entry Price"
                    variant="outlined"
                    name="entryPrice"
                    value={formData.entryPrice}
                    type="number"
                    onChange={handleChange}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Take Profit"
                    variant="outlined"
                    name="takeProfit"
                    value={formData.takeProfit}
                    type="number"
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Stop Loss"
                    variant="outlined"
                    name="stopLoss"
                    value={formData.stopLoss}
                    type="number"
                    onChange={handleChange}
                />
                <FormControl fullWidth margin="normal" required>
                    <InputLabel id="action-label">Action</InputLabel>
                    <Select
                        labelId="action-label"
                        value={formData.action}
                        label="Action"
                        name="action"
                        onChange={handleChange}
                    >
                        <MenuItem value="Buy">Buy</MenuItem>
                        <MenuItem value="Sell">Sell</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3 }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
