import * as React from 'react';
import partner from "../img/partner.png";
import Button from '@mui/material/Button';
function Partner() {
    return (
        <section id="features" class="section-home-feature">
            <div class="padding-global">
                <div class="container-medium">
                    <div class="padding-section-large">
                        <div class="home-feature-component">
                            <div id="w-node-_7dacd93b-a131-7011-23ef-71f2bb96d242-438edebb" class="home-feature-content-wrapper">
                                <h2 id="w-node-_7dacd93b-a131-7011-23ef-71f2bb96d243-438edebb" class="heading-1">Verified Partners</h2>
                                <div class="spacer-small"></div>
                                <div class="spacer-large"></div>
                                <div class="home-feature-item">
                                    <div class="spacer-xsmall"></div>
                                    <div>Explore our trusted partners for guidance or HFT passing services. Interested in partnering with us? Sign up for our partnership program today!</div>
                                </div>
                                <a target="_blank" rel="noreferrer" href="https://www.multiverse-funding.com/partners"><Button variant="contained"
                                    size="large"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#0c664f",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "#08573e", // Darker shade for hover effect
                                        },
                                        "&:active": {
                                            backgroundColor: "#0c664f",
                                            color: "white",
                                        },
                                        "&:focus": {
                                            outline: "none", // Optional: Remove focus outline
                                        },
                                    }}>Check Verified Partners</Button></a>

                            </div>
                            <div id="w-node-_7dacd93b-a131-7011-23ef-71f2bb96d247-438edebb" class="home-feature-image-wrapper"><img src={partner} loading="lazy" width="287" style={{ opacity: 1 }} alt="Platform gauge screenshot" data-w-id="7dacd93b-a131-7011-23ef-71f2bb96d248" class="home-feature-image" />
                                <div data-w-id="7a58e8ee-59c1-a836-9947-1aff9f70ead9" style={{ opacity: 1 }} class="home-feature-glow"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Partner;
